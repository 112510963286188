// React imports
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

// reducer imports
import { checkUser } from '../../redux/reducers/userReducer';
import { fetchConversations } from '../../redux/reducers/conversationReducer';
import { setSessionSelection, setViewSessionMenu } from '../../redux/reducers/historyReducer';

//utility function imports
import { HistoryResults, premNotification } from '../../utilities/history';
// @ts-ignore
import { CaptionBubbleColor, FontSizeScale } from '../Accessibility/AccessibilitySettings.js';
import NotesEditor from '../UserTools/NotesEditor';

// Material UI imports
import { Button } from 'antd';
import { List, ListItem, ListItemButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
	XMarkIcon,
} from '@heroicons/react/24/outline';
import uuid from 'react-uuid';
import HelpCenter from '../SiteTools/HelpCenter';

// Required for Material UI
const theme = createTheme({
	components: {
		// Name of the component
		MuiButtonBase: {
			defaultProps: {
				// The props to change the default for.
			},
		},
	},
});

function HistoryContainer({
	handleSubscription,

}) {
	const selectedSession = useSelector((state) => state.history.selectedSession);
	const sessionSelection = useSelector((state) => state.history.sessionSelection);
	const viewSessionMenu = useSelector((state) => state.history.viewSessionMenu);
	const [sessionID, setSessionID] = useState(0);
	const [roomId, setRoomId] = useState('');
	const [sessionName, setSessionName] = useState('');
	const navigate = useNavigate()
	const currentUser = useSelector((state) => state.user.currentUser);
	const [noteButton, setNoteButton] = useState('');
	// Note Section
	const [noteSection, setNoteSection] = useState(false);
	const noteSectionView = noteSection ? 'block' : 'hidden';
	const userConversation = useSelector((state) => state.conversation.userConversation);
	const [premUser, setPremUser] = useState(false);
	// search result
	const [result, setResult] = useState(userConversation);
	//session settings
	let sessionMenu = viewSessionMenu ? 'visible w-full' : 'hidden';
	let inSessionView = sessionSelection ? 'visible w-full' : 'hidden';
	const dispatch = useDispatch();
	let sessionTextSize = !sessionSelection ? 'base' : 'sm';
	// toggle ascending or descending for date
	const [desc, setDesc] = useState(false);
	// Set session ID for deletion
	const [sessionIDforDeletion, setSessionIDforDeletion] = useState(0);
	const [updatedConversation, setUpdatedConversation] = useState('');
	// render session/message data based on descending or ascending
	const [sessionData, setSessionData] = useState();
	// Delete Session Modal
	const [deleteSession, setDeleteSession] = useState(false);
	const [bgColorEnabled, setBgColorEnabled] = useState(true);
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);

	//check is user is signed in
	useEffect(() => {
		dispatch(fetchConversations);
		dispatch(checkUser);
	}, [dispatch]);

	useEffect(() => {
		if (currentUser && currentUser.subscriptionId !== null) {
		  setPremUser(true);
		} else {
		  setPremUser(false);
		}
	  }, [currentUser]);
	  

	//conditional render of dashboard
	useEffect(() => {
		if (window.location.pathname === '/home') {
			setNoteButton('hidden');
			setNoteSection(false);
		} else {
			return;
		}
	}, []);

	//sets a user selected session history in redux
	const handleHistoryDetails = (session) => {
		dispatch({ type: 'SET_SESSION_SELECTION', payload: true });
		dispatch({ type: 'SET_VIEW_SESSION_MENU', payload: false });
		dispatch({ type: 'SET_SELECTED_SESSION', payload: session });
		navigate('/history');
	};

	useEffect(() => {
		setSessionSelection(true);
		setViewSessionMenu(false);

		if (selectedSession !== null) {
			setSessionID(selectedSession.id);
			setRoomId(selectedSession.roomId);
			setSessionName(selectedSession.name);
		} else {
			return
		}
	}, [selectedSession]);

	// exits history selection
	function exitSession() {
		dispatch({ type: 'SET_SESSION_SELECTION', payload: false });
		dispatch({ type: 'SET_VIEW_SESSION_MENU', payload: true });
	}

	// renders session data of selected history item
	const renderSessionData = ({ desc, premUser }) => {
		if (userConversation && userConversation.length > 0) {
			let convoArr = userConversation.map((session) => {
				const sessionId = session.id;
				if (session.conversation.length === 0) {
					handleDeleteSession(sessionId);
					return null; // return null to skip rendering the item
				}
				// Sortable by date
				return (
					<ThemeProvider theme={theme} key={uuid()}>
						<ListItem
							disablePadding
							sx={{
								borderTop: '0.25px solid black',
								'&:hover': {
									border: '2px solid #2A2A2A',
								},
							}}>

							<ListItemButton onClick={() => handleHistoryDetails(session)}>
								<div
									className={`w-full p-2 h-full whitespace-nowrap overflow-auto text-ellipsis text-${sessionTextSize}`}>
									<div className='flex flex-row items-center justify-between w-full'>
										<div className='flex flex-row items-center space-x-4'>
											<div className=' p-1 border rounded-full bg-gray-300 text-white m-2'>
												<MessageOutlinedIcon />
											</div>
											<div className='flex flex-col'>
												<em className='font-bold'>
												{session.name} {session.name && `(${new Date(session.createdAt)
    .toLocaleString(navigator.language, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    .replace(',', ' @')})`}
												</em>
											</div>
										</div>
									</div>
								</div>
							</ListItemButton>
							<div>
								<button
									className='p-2 m-4 border rounded-full hover:bg-gray-300'
									onClick={() =>
										setDeleteSession(true) & setSessionIDforDeletion(sessionId)
									}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
										/>
									</svg>
								</button>
							</div>
						</ListItem>
					</ThemeProvider>
				);
			});
			if (premUser) {
				if (desc) {
					return convoArr.slice(0, 20).reverse();
				} else {
					return convoArr.slice(0, 20);
				}
			} else {
				if (desc) {
					return convoArr.slice(0, 2).reverse();
				} else {
					return convoArr.slice(0, 2);
				}
			}
		} else {
			return (<div style={{ backgroundColor: 'white' }}><center>You have no sessions saved!</center></div>);
		}
	};

	// delete specific session
	function handleDeleteSession(key) {
		axios
			.patch(
				`/api/v1/conversations/${key}/delete-participant/${currentUser.id}`
			)
			.then(dispatch(fetchConversations));
		setDeleteSession(false);

	}

	//get session message data
	useEffect(() => {
		if (sessionID === 0) {
			return;
		} else {
			const id = sessionID;
			axios.get(`/api/v1/conversations/${id}/conversation-history`).then((res) => {
				const convoData = res.data;
				setSessionName(convoData.name || []);
				setUpdatedConversation(convoData.conversation);
			});
		}
	}, [sessionID]);

	const handleSettingsToggle = () => {
		setIsSettingsOpen(!isSettingsOpen);
	}

	// Toggle button for ascending or descending
	const toggleDesc = () => {
		setDesc(!desc);
	};

	// useEffect for updating sessionData when desc is toggled
	useEffect(() => {
		if (premUser) {
			setSessionData(renderSessionData({ desc, premUser: true }, result));
		} else {
			setSessionData(renderSessionData({ desc, premUser: false }, result));
		}
	}, [
		desc,
		premUser,
		result,
		userConversation,
		sessionSelection,
		sessionID,
		sessionName,
		updatedConversation,
	]);

	return (
		<>
			{/* <h>Session History</h> */}

			<div
				className={`${sessionMenu} flex flex-row rounded-lg p-4 w-full `}
				style={{ paddingBottom: '75px' }}>
				{/* Session/Search Container */}
				<div className='flex flex-col space-y-4 w-full items-center justify-center p-2'>
					<div className='flex flex-col sm:flex-row w-full justify-between sm:items-center'>
						<h1 className='font-bold text-2xl'>
							Session History &nbsp;
							<HelpCenter />
						</h1>
						{/* Button to toggle asc and desc */}
						<div className='flex flex-row items-center space-x-4  '>
							<p className='font-bold'>Sort</p>{' '}
							<button
								className='flex space-x-2 rounded-lg p-2 border bg-button-secondary hover:bg-button-secondary-hover text-black'
								onClick={toggleDesc}>
								<h1> {desc ? 'Oldest' : 'Newest'} </h1>
							</button>
						</div>
					</div>

					{/* Session History List Container*/}
					<div className={` h-full w-full`}>
						<List
							disablePadding
							sx={{
								width: '100%',
								height: '100%',
								flexDirection: 'column',
								alignItems: 'center',
								overflowX: 'auto',
							}}>
							{sessionData ? sessionData : "You have no sessions saved"}
							{/* Session Data */}
							{userConversation.length > 2 ? premNotification(premUser, Link, handleSubscription) : null}
						</List>
					</div>
				</div>
			</div>

			{/* Delete Session */}
			<Transition.Root show={deleteSession} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={setDeleteSession}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 overflow-y-auto'>
						<div
							className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'
							style={{ alignItems: 'center' }}>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
								<Dialog.Panel className='relative transform overflow-auto rounded-2xl bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
									<div className='absolute top-0 right-0 hidden pt-4 pr-4 sm:block'>
										<button
											type='button'
											className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
											onClick={() => setDeleteSession(false)}>
											<span className='sr-only'>Close</span>
											<XMarkIcon className='h-6 w-6' aria-hidden='true' />
										</button>
									</div>
									<div className='sm:flex sm:items-start'>
										<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
											<Dialog.Title
												as='h3'
												className='text-xl font-medium leading-6 text-gray-900 justify-center border-b-2 border-gray-300 m-2 pb-4'>
												Delete Session?
											</Dialog.Title>
											<div className='mt-2'>
												<p
													className='text-sm text-gray-500'
													style={{ textAlign: 'justify', padding: '8px' }}>
													Are you sure you want to delete your session? All of
													your data will be permanently removed from our servers
													forever. This action cannot be undone.
												</p>
											</div>
										</div>
									</div>
									<div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
										<Button
											size='small'
											type='button'
											className='inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
											style={{
												padding: '1em 2em',
												border: 0,
												borderRadius: '30px',
												color: 'black',
												backgroundColor: '#D8F55A',
												textAlign: 'center',
												fontSize: '12px',
												fontWeight: 'bold',
												fontFamily: 'sans-serif',
											}}
											onClick={() =>
												handleDeleteSession(sessionIDforDeletion) &&
												setDeleteSession(false) &&
												dispatch(fetchConversations)
											}>
											Delete
										</Button>
										<Button
											size='small'
											type='button'
											className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm'
											style={{
												padding: '1em 2em',
												border: '1px solid black',
												borderRadius: '30px',
												color: 'black',
												backgroundColor: 'white',
												textAlign: 'center',
												fontSize: '12px',
												fontWeight: 'bold',
												fontFamily: 'sans-serif',
											}}
											onClick={() => setDeleteSession(false)}>
											Cancel
										</Button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			{/* Transcription Container - Hidden at start and opens when item is selected */}
			<div className={` ${inSessionView} flex flex-row w-full h-full`}>
				<div
					className={` flex flex-col rounded-lg p-1 space-y-2 h-full w-full`}>
					{/* Session Name */}
					<div className='flex flex-row justify-between'>
						<div className='flex space-x-4'>
							<button
								className={`rounded-lg flex flex-row justify-center space-x-4 border font-bold leading-tight shadow-sm hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out p-2`}
								onClick={exitSession}>
								Back
							</button>

							<button
								className={`${noteButton} rounded-lg flex flex-row justify-center space-x-4 border font-bold  leading-tight shadow-sm p-2`}
								onClick={() => {
									setNoteSection(!noteSection);
								}}>
								{noteSection ? 'Hide Notes' : 'Show Notes'}
							</button>

							<h1 className='text-2xl font-bold'>{sessionName}</h1>
						</div>
						<button
							className={`inline-flex items-center rounded-full border border-transparent bg-gray-600 p-2 mr-4 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
							onClick={handleSettingsToggle}>
							<SettingsRoundedIcon />
						</button>
					</div>

					{isSettingsOpen && (
						<div
							className="bg-white p-4 rounded-lg shadow-xl mt-2"
							style={
								window.innerWidth < 487 ?
									{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										position: 'fixed',
										top: 60,
										right: noteSection ? 225 : 25,
										paddingRight: 40,
										zIndex: 9999,
										boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',

									} : window.innerWidth >= 487 && window.innerWidth < 1200 ? {
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										position: 'fixed',
										top: 60,
										right: noteSection ? 350 : 25,
										paddingRight: 40,
										zIndex: 9999,
										boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
									} : {
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										position: 'fixed',
										top: 60,
										right: noteSection ? 490 : 25,
										paddingRight: 40,
										zIndex: 9999,
										boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
									}
							}>
							{updatedConversation &&
								updatedConversation[0].singleSpeaker === true ? null :
								<>
									<CaptionBubbleColor />

									<div style={{ width: '100%', margin: '16px 0', borderTop: '1px solid black', marginLeft: 30 }}></div>
								</>
							}
							<FontSizeScale />

						</div>
					)}

					{/* Conversation Section */}
					<div className={`w-full p-2 h-full`}>
						{/* Conversation */}
						{HistoryResults(bgColorEnabled, updatedConversation, uuid, currentUser, roomId)}
					</div>
				</div>

				<div
					className={`${noteSectionView} w-1/2 m-1 border rounded-xl`}>
					<div className={` flex h-full w-full border rounded-xl`}>
						<div className='relative flex flex-col w-full'>
							<div className='flex items-center justify-between px-4 py-2'>
								<h1 className='font-semibold'>Note Section</h1>
								<button
									className='text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700'
									onClick={() => setNoteSection(false)}>
									<VisibilityOffIcon />
								</button>
							</div>
							<NotesEditor
								roomId={roomId}
								userId={currentUser ? currentUser.id : ''}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default HistoryContainer;
