import React from 'react';
import Recognizer from '../components/UserTools/Recognizer';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

function Session({
	fontSize,
	display,
}) {
	const currentUser = useSelector((state) => state.user.currentUser);

	return (
		<>
			<Helmet>
        <title>Imanyco Koda - Session</title>
      </Helmet>
			<div
				className={`${display} w-full flex flex-col p-1 h-screen space-y-2 text-${fontSize}`}
				style={
					window.innerWidth >= 768 && currentUser
						? { paddingBottom: 0, overflowX: 'hidden', maxWidth: '100%' }
						: { paddingBottom: 57, overflowX: 'hidden', maxWidth: '100%' }
				}>
				<Recognizer display={display} />
			</div>
		</>
	);
}

export default Session;
