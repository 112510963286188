import React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AccountSettings from '../components/Account/AccountSettings.js';
import PreferenceSettings from '../components/Accessibility/PreferenceSettings.js';
import SubscriptionSettings from '../components/Subscription/SubscriptionSettings.js';
import AccountManagement from '../components/Account/AccountManagement.js';
import Feedback from '../components/SiteTools/Feedback.js';
import ReactGA from 'react-ga';
import HelpCenter from '../components/SiteTools/HelpCenter.jsx';

function Account({
	display,
	isSub,
	fontSize,
	setFontSize
}) {
	//google analytics
	ReactGA.initialize('UA-189074005-1');
	ReactGA.pageview('/account');

	// Account Settings Editable fields & states

	let displayAccountSettingsView = (
		<AccountSettings
			display={display}
		/>
	);
	let PreferenceSettingsView = (
		<PreferenceSettings
			fontSize={fontSize}
			setFontSize={setFontSize}
			display={display}
		/>
	);
	let SubscriptionSettingsView = (
		<SubscriptionSettings
			display={display}
		/>
	);

	let AccountManagementView = (
		<AccountManagement
			display={display}
		/>
	);

	let FeedbackView = (
		<Feedback
			display={display}
		/>
	);

	// Display Settings View
	const [isMobile, setIsMobile] = useState(false);
	const [settingsView, setSettingsView] = useState(
		isSub ? SubscriptionSettingsView : displayAccountSettingsView
	);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 768) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		window.addEventListener('resize', handleResize);

		// Check on mount in case the user loads the page at mobile size
		handleResize();

		// Clean up event listener on unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div className={`${display} flex flex-col lg:flex-row space-x-1 h-full lg:h-screen justify-between w-full`}>
		  <Helmet>
			<title>Imanyco Koda - Account</title>
		  </Helmet>
		  <div className={`w-full h-full md:pl-24 flex flex-col lg:space-x-12 space-y-8 text-left justify-left`}
			style={{ overflowX: 'hidden', maxWidth: '100%' }}>
			{/* menu to display each settings view */}
			<div className='flex flex-col justify-start pt-12 px-4'>
			  <div className='text-3xl font-bold pb-16 flex flex-row space-x-4 items-center'>
				<h1>Account</h1>
				<HelpCenter />
			  </div>
	  
			  <div className={`items-center flex flex-row justify-evenly text-gray-400 text-${isMobile ? 'xs' : 'md'} lg:text-xl font-bold`}>
				<button
				  className='hover:text-gray-800 hover:underline active:text-gray-800 active:underline text-md lg:text-xl'
				  onClick={() => setSettingsView(displayAccountSettingsView)}>
				  General
				</button>
				<span className='text-gray-300 text-2xl'> |</span>
				<button
				  className='hover:text-gray-800 hover:underline active:text-gray-800 active:underline text-md lg:text-xl'
				  onClick={() => setSettingsView(SubscriptionSettingsView)}>
				  Subscription
				</button>
				<span className='text-gray-300 text-2xl'> |</span>
				<button
				  className='hover:text-gray-800 hover:underline active:text-gray-800 active:underline text-md lg:text-xl'
				  onClick={() => setSettingsView(PreferenceSettingsView)}>
				  Preferences
				</button>
				<span className='text-gray-300 text-2xl'> |</span>
				<button
				  className='hover:text-gray-800 hover:underline active:text-gray-800 active:underline text-md lg:text-xl'
				  onClick={() => setSettingsView(AccountManagementView)}>
				  Settings
				</button>
				<span className='text-gray-300 text-2xl'> |</span>
				<button
				  className='hover:text-gray-800 hover:underline active:text-gray-800 active:underline text-md lg:text-xl'
				  onClick={() => setSettingsView(FeedbackView)}>
				  Feedback
				</button>
			  </div>
			</div>
			{/* display settings view */}
			<div className='flex justify-center items-center pb-24 w-full'>
			  {settingsView}
			</div>
		  </div>
		</div>
	  );
	  
}

export default Account;