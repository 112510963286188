import React, { useState, useEffect, useRef, useCallback } from "react";
import ChatSocket from "./ChatSocket";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Tour from "reactour";
import { handleDeleteSession } from "../../utilities/conversation.js";
import { SessionResultContainer } from "../../utilities/conversation.js";
import QRCode from "react-qr-code";
import uuid from "react-uuid";
import { Button } from "antd";
import { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import "../css/styles.css";

// Notification for Session
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

// Text Editor
import NotesEditor from "./NotesEditor";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import { Alert, TextField, Divider } from "@mui/material";
import { useNavigate } from "react-router";
import SendIcon from "@mui/icons-material/Send";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
// @ts-ignore
import {
  CaptionBubbleColor,
  FontSizeScale,
} from "../Accessibility/AccessibilitySettings.js";
import Mixpanel from "mixpanel-browser";

import { setSpeakerNames } from "../../redux/reducers/conversationReducer";
require("dotenv").config();
// const URL = process.env.PUBLIC_URL || "https://localhost:3001";
const URL = process.env.PUBLIC_URL || "koda.imanyco.com";
// const kaldiServerUrl = process.env.PUBLIC_URL || 'pandaa2.info';

Mixpanel.init("50158065c2effca4d574cb213ffde886", {
  debug: true,
  ignore_dnt: true,
});

function Recognizer({ display }) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const speakerNames = useSelector((state) => state.conversation.speakerNames);
  const isEditing = useSelector((state) => state.conversation.isEditing);
  const dispatch = useDispatch();
  const speakerNamesRef = useRef();
  const [planType, setPlanType] = useState("");
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [time, setTime] = useState(0);
  const [userTime, setUserTime] = useState(0);
  const [sessionName, setSessionName] = useState("");
  const [comments, setComments] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);

  //session settings

  const navigate = useNavigate();
  const [sessionNotification, setSessionNotification] = useState(false);
  const [inSession, setInSession] = useState(false);
  const inSessionView = inSession ? "block" : "hidden";
  const notInSessionView = inSession ? "hidden" : "block";
  const [isFullScreen, setIsFullScreen] = useState(false);
  const params = useParams();
  const { messages, sendMessage } = ChatSocket(params.roomId); // Creates a websocket and manages messaging
  const roomUUID = window.location.pathname.replace("/", "");
  const [singleSpeaker, setSingleSpeaker] = useState();
  const [isMicOn, setIsMicOn] = useState(false);
  const [isSingleListening, setIsSingleListening] = useState(false);
  const [isMultiListening, setIsMultiListening] = useState(false);
  const [isSessionTimeout, setIsSessionTimeout] = useState(false);
  const [isDiarizationTimeMax, setIsDiarizationTimeMax] = useState(false);
  const [interimTranscript, setInterimTranscript] = useState("");
  const [conversation, setConversation] = useState([]);
  const [bgColorEnabled, setBgColorEnabled] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [speakerData, setSpeakerData] = useState();
  const [maxSpeakers, setMaxSpeakers] = useState(3);
  const [isRoomMax, setIsRoomMax] = useState(false);
  // Scroll to bottom of chat
  const messagesEndRef = useRef(null);
  // Notification
  const [saveSession, setSaveSession] = useState(false);

  // Note Section
  const [noteSection, setNoteSection] = useState(false);
  const noteSectionView = noteSection ? "block" : "hidden";
  const noNoteSectionView = noteSection ? "hidden" : "block";

  // Invite
  const [invite, setInvite] = useState(false);
  const [copied, setCopied] = useState(false);

  const [isTourOpen, setIsTourOpen] = useState(false);

  const copiedIcon = copied ? (
    <FileDownloadDoneOutlinedIcon />
  ) : (
    <ContentCopyOutlinedIcon />
  );

  // Speaker Selection
  const [showSpeakerSelection, setShowSpeakerSelection] = useState(false);
  const [showMultipleMax, setShowMultipleMax] = useState(false);

  //Web Speech API
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();
  recognition.continuous = isSingleListening ? true : false;
  recognition.interimResults = true;
  recognition.lang = "en-US";

  useEffect(() => {
    // Check if the tour has already been started
    const hasTourStarted = localStorage.getItem("sessionTourStarted");

    if (!hasTourStarted) {
      // Start the tour if it hasn't been started before
      setIsTourOpen(true);
      localStorage.setItem("sessionTourStarted", "true");
    }
  }, []);

  const tourSteps = [
    {
      selector: "#start-button",
      content: "Click here to start your transcription",
    },
    {
      selector: "#mute-button",
      content:
        "Click here if you would like to mute your microphone at any point during your session",
    },
    {
      selector: "#invite-button",
      content: "Use this button to invite other people to your session",
    },
    {
      selector: "#settings-button",
      content:
        "Click here to adjust the size of the font and change the color of the caption bubbles",
    },
    {
      selector: "#fullScreen-button",
      content: "Click here to enter full screen mode",
    },
  ];

  const closeTour = () => {
    setIsTourOpen(false);
  };

  useEffect(() => {
    speakerNamesRef.current = speakerNames;
  }, [speakerNames]);

  useEffect(() => {
    dispatch(setSpeakerNames({}));
  }, []);

  // Live Transcription Feed
  useEffect(() => {
    if (interimTranscript === "") {
      return;
    } else {
      sendMessage({
        interimTranscript: interimTranscript,
        isInterim: true,
        singleSpeaker: singleSpeaker,
      });
    }
  }, [interimTranscript]);

  // Final Transcription Feed
  useEffect(() => {
    if (!speakerData) {
      return;
    }

    sendMessage(speakerData);
    setConversation((prevConversation) => [...prevConversation, speakerData]);
    setInterimTranscript("");
  }, [speakerData]);

  //single speaker speech to text
  useEffect(() => {
    let recognitionTimer;
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.continuous = isSingleListening ? true : false;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    // define the onresult event listener outside of the isSingleListening block
    const onResult = (event) => {
      let finalTranscript = "";
      let interimTranscript = "";

      for (let i = event.resultIndex; i < event.results.length; i++) {
        let transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript + " ";
          interimTranscript = "";
        } else {
          interimTranscript += transcript;
        }
      }

      if (isSingleListening) {
        if (finalTranscript.trim() || interimTranscript.trim()) {
          if (finalTranscript.trim()) {
            setSpeakerData({
              text: finalTranscript.trim(),
              singleSpeaker: singleSpeaker,
            });
          }
          if (interimTranscript.trim()) {
            setInterimTranscript(interimTranscript);
          }
        }
        // set the timer based on the planType
        if (planType === "basic") {
          recognitionTimer = setTimeout(() => {
            setIsSessionTimeout(true);
            setSessionNotification(true);
          }, 60 * 30 * 1000); // 30 minutes
        } else if (planType === "monthly" || planType === "annual") {
          recognitionTimer = setTimeout(() => {
            setIsSessionTimeout(true);
            setSessionNotification(true);
          }, 120 * 60 * 1000); // 120 minutes
        }
      } else {
        // stop recognition and clear the timer if isSingleListening is false
        recognition.stop();
        clearTimeout(recognitionTimer);
        setInterimTranscript("");
      }
    };

    recognition.addEventListener("result", onResult);

    if (isSingleListening) {
      recognition.start();
    }

    // add an event listener to restart recognition on end
    const onEnd = () => {
      if (isSingleListening) {
        recognition.start();
      }
    };
    recognition.addEventListener("end", onEnd);

    // return a cleanup function to remove event listeners and stop recognition
    return () => {
      recognition.removeEventListener("result", onResult);
      recognition.removeEventListener("end", onEnd);
      recognition.stop();
    };
  }, [
    isSingleListening,
    planType,
    singleSpeaker,
    setSpeakerData,
    setInterimTranscript,
    setIsSessionTimeout,
    setSessionNotification,
  ]);

  //Multi Speaker Diarization & Speech To Text
  let recordTranscribe = null;

  useEffect(() => {
    if (isMultiListening) {
      recordTranscribe = new window.sonioxWebVoice.RecordTranscribe();
      recordTranscribe.setApiKey(process.env.REACT_APP_SONIOX_API_KEY);
      recordTranscribe.setOnStarted(onStarted);
      recordTranscribe.setOnPartialResult(onPartialResult);
      recordTranscribe.setOnFinished(onFinished);
      recordTranscribe.setOnError(onError);
      recordTranscribe.setIncludeNonFinal(true);
      recordTranscribe.setEnableStreamingSpeakerDiarization(true);
      recordTranscribe.setMinNumSpeakers(1);
      recordTranscribe.setMaxNumSpeakers(maxSpeakers);

      recordTranscribe.start();
    } else {
      if (recordTranscribe !== null) {
        recordTranscribe.stop();
        recordTranscribe.cancel();
        recordTranscribe = null;
      }
    }

    return () => {
      if (recordTranscribe !== null) {
        recordTranscribe.stop();
        recordTranscribe.cancel();
        recordTranscribe = null;
      }
    };
  }, [isMultiListening]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.soniox.com/soniox-web-voice.js";
    script.async = true;
    script.onload = () => {
      // The sonioxWebVoice library is loaded
    };
    document.body.appendChild(script);

    return () => {
      if (recordTranscribe != null) {
        recordTranscribe.cancel();
        recordTranscribe = null;
      }
      document.body.removeChild(script);
    };
  }, []);

  let currentSpeaker = "";
  let transcriptFinal = "";
  let transcriptNonFinal = "";
  let speakerTranscripts = {};
  let timerId = null;
  const TIMEOUT_MS = 3000; // 3 seconds timeout

  const onStarted = useCallback(() => {
    startTimer();
    let recognitionTimer;
    let diarizationTimer;
    const duration = planType === "basic" ? 15 * 60 * 1000 : 120 * 60 * 1000;
    const maxDiarizationTime =
      planType === "basic"
        ? Math.round((15 - userTime / 60) * 60 * 1000)
        : Math.round((1000 - userTime / 60) * 60 * 1000);

    recognitionTimer = setTimeout(() => {
      recordTranscribe.stop();
      setIsSessionTimeout(true);
      setSessionNotification(true);
      isSingleListening
        ? setIsSingleListening(false)
        : setIsMultiListening(false);
    }, duration);

    diarizationTimer = setTimeout(() => {
      recordTranscribe.stop();
      setSessionNotification(true);
      setShowMultipleMax(true);
      isSingleListening
        ? setIsSingleListening(false)
        : setIsMultiListening(false);
    }, maxDiarizationTime);

    console.log("onStarted");
  }, [planType, startTimer, isSingleListening]);

  const onPartialResult = useCallback(
    (result) => {
      result.words.forEach(function ({ text, is_final, speaker }) {
        text = `${text} `;
        if (is_final) {
          clearTimeout(timerId);
          timerId = setTimeout(setSpeakerDataTimeout, TIMEOUT_MS);

          if (speaker !== currentSpeaker) {
            if (currentSpeaker && speakerTranscripts[currentSpeaker]) {
              const speakerJson = {
                speaker: currentSpeaker,
                userSpeakerNames: speakerNamesRef.current,
                text: speakerTranscripts[currentSpeaker],
                singleSpeaker: singleSpeaker,
                isInterim: false,
              };
              setSpeakerData(speakerJson);
              setInterimTranscript("");
              transcriptNonFinal = "";
            }
            currentSpeaker = speaker;
            speakerTranscripts[currentSpeaker] = "";
          }

          if (typeof text === "string" && text.trim() !== "") {
            speakerTranscripts[currentSpeaker] += text;
            transcriptFinal = Object.values(speakerTranscripts).join("");
          }
        } else {
          if (text && transcriptNonFinal.indexOf(text) === -1) {
            transcriptNonFinal += text;
            setInterimTranscript(transcriptNonFinal);
            clearTimeout(timerId);
            timerId = setTimeout(setSpeakerDataTimeout, TIMEOUT_MS);
          }
        }
      });
    },
    [setSpeakerData, setInterimTranscript, singleSpeaker]
  );

  const setSpeakerDataTimeout = useCallback(() => {
    const speakerJson = {
      speaker: currentSpeaker,
      userSpeakerNames: speakerNamesRef.current,
      text: speakerTranscripts[currentSpeaker],
      singleSpeaker: singleSpeaker,
      isInterim: false,
    };
    setSpeakerData(speakerJson);
    transcriptNonFinal = "";
    setInterimTranscript("");
    currentSpeaker = "";
  }, [currentSpeaker, setSpeakerData, setInterimTranscript, singleSpeaker]);

  function onFinished() {
    console.log("onFinished");
  }

  function onError(status, message) {
    console.log("onError status=" + status + ", message=" + message);
  }

  //toggles single speaker microphone
  const toggleSingleListening = () => {
    if (!isSingleListening) {
      setIsSingleListening(true);
      recognition.start();
    } else {
      setIsSingleListening(false);
      recognition.stop();
    }
    setIsMicOn(!isMicOn);
    setSpeakerData("");
  };

  //toggles multi speaker microphone
  const toggleMultiListening = () => {
    if (!isMultiListening) {
      setIsMultiListening(true);
      setIsMicOn(true);
      setSpeakerData("");
    } else {
      setIsMultiListening(false);
      setIsMicOn(false);
    }
  };

  //starts single speaker session
  const handleClickSingleSpeaker = () => {
    setSingleSpeaker(true);
    setShowSpeakerSelection(false);
    setIsSingleListening(true);
    setLoading(false);
    setReady(true);
    setInSession(true);
    setIsMicOn(true);
    Mixpanel.track("Single Speaker Session Started", {
      distinct_id: currentUser.id,
    });
  };

  //start multi speaker session
  const handleClickMultipleSpeakers = () => {
    setSingleSpeaker(false);
    setShowSpeakerSelection(false);
    setIsMultiListening(true);
    setLoading(false);
    setReady(true);
    setInSession(true);
    setIsMicOn(true);
    Mixpanel.track("Multi-Speaker Session Started", {
      distinct_id: currentUser.id,
    });
  };

  //Set room admin status
  useEffect(() => {
    const id = roomUUID;
    axios
      .get(`/api/v1/conversations/${id}/conversation-info`)
      .then((res) => {
        const uid = res.data.UserId;
        console.log("id", uid);
        if (currentUser?.id === undefined) {
          setIsAdmin(false);
        } else if (uid === currentUser?.id) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [roomUUID, currentUser?.id]);

  console.log(currentUser?.id);

  //Set User Subscription Type
  useEffect(() => {
    setPlanType(currentUser?.subscription);
  }, [currentUser?.subscription]);

  //Set max speakers (diarization)
  useEffect(() => {
    switch (planType) {
      case "basic":
        setMaxSpeakers(3);
        break;
      case "monthly":
      case "annual":
        setMaxSpeakers(6);
        break;
    }
  }, [planType]);

  const handleSettingsToggle = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  //get session times
  useEffect(() => {
    axios
      .get(`/api/v1/session-times`)
      .then((res) => {
        if (res.data.length > 0) {
          setUserTime(res.data[0].duration);
        } else {
          setUserTime(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  //Session Timer
  useEffect(() => {
    let intervalId;

    if (time > 0) {
      intervalId = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [time]);

  function startTimer() {
    setTime(1);
  }

  function stopTimer() {
    setTime(0);
  }

  // check if userTime is over the limit based on planType
  useEffect(() => {
    if (planType === "basic") {
      setIsDiarizationTimeMax(userTime >= 900); // 15 minutes
    } else if (planType === "monthly" || planType === "annual") {
      setIsDiarizationTimeMax(userTime >= 60000); // 1000 minutes
    }
  }, [planType, userTime]);

  //update user session time
  const submitSessionTime = () => {
    const sessionTime = {
      duration: time + userTime,
    };

    axios
      .post(
        `/api/v1/session-times/${currentUser.id}/add-session-time`,
        sessionTime
      )
      .then((res) => {
        stopTimer();
      })
      .catch((err) => {
        console.error(err);
        // handle error here, e.g. show error message to user
      });
  };

  //Save user conversation at end of session
  const handleSaveHistory = async (e) => {
    e.preventDefault();

    const userConversation = {
      name: sessionName,
      conversation: conversation,
    };

    try {
      const res = await axios.patch(
        `/api/v1/conversations/${roomUUID}/save-history`,
        userConversation
      );

      if (res.status === 200) {
        setSuccess("Conversation Saved!");
      } else {
        setError("Conversation Not Saved!");
      }

      // Reset State
      await submitSessionTime();
      setSessionNotification(false);
      setIsMicOn(false);

      if (isSingleListening) {
        setIsSingleListening(false);
      } else {
        setIsMultiListening(false);
      }

      navigate("/home");
    } catch (error) {
      console.error(error);
      setError("Failed to save conversation.");
    }
  };

  //check if room is max participants
  useEffect(() => {
    axios
      .get(`/api/v1/conversations/${roomUUID}/conversation-info`)
      .then((response) => {
        const participants = response.data.participants;
        if (planType === "basic") {
          setIsRoomMax(participants && participants.length === 3);
        } else if (planType === "monthly" || planType === "annual") {
          setIsRoomMax(participants && participants.length === 6);
        } else {
          return;
        }
      })
      .catch((error) => {
        console.error(error);
        // handle error
      });
  }, [roomUUID, planType]);

  // Full screen mode
  function toggleFullScreen() {
    setIsFullScreen(!isFullScreen);
  }

  const handleCopyLink = () => {
    const link = "https://koda.imanyco.com/" + roomUUID;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link to clipboard:", error);
      });
  };

  //scroller for new messages
  const scrollToBottom = () => {
    if (!isEditing) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(scrollToBottom, [messages, isEditing]);

  return (
    <div
      className={` md:pl-36 flex flex-row h-full w-full`}
      style={{ overflow: isTourOpen ? "hidden" : "scroll", height: "100vh" }}
    >
      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        // onAfterOpen={handleTourStepChange}
      />
      <div
        className={`flex flex-col items-center h-full w-full py-4 pr-6`}
        style={
          window.innerWidth < 435
            ? { paddingRight: "0rem" }
            : { paddingRight: "1.5rem" }
        }
      >
        <div
          className={`${display} pt-2 sticky top-0 border-b flex justify-between w-full items-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl pb-4 `}
        >
          {isAdmin ? (
            <>
              <div className="flex items-center">
                <div className={`${notInSessionView} mr-2`}>
                  <button
                    id="start-button"
                    className="inline-flex items-center p-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    onClick={() => {
                      setShowSpeakerSelection(!showSpeakerSelection);
                      Mixpanel.track("New Session Started", {
                        distinct_id: currentUser.id,
                      });
                    }}
                    style={
                      window.innerWidth < 350
                        ? { padding: "0.5rem" }
                        : { paddingTop: "0.5rem", paddingBottom: "0.5rem" }
                    }
                  >
                    <h1
                      style={
                        window.innerWidth < 350
                          ? { fontSize: "xx-small", fontWeight: "bold" }
                          : { fontSize: "inherit" }
                      }
                    >
                      <PlayArrowIcon />
                    </h1>
                  </button>
                </div>
                <div className={`${inSessionView} mr-2`}>
                  <button
                    className="inline-flex items-center p-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    onClick={() => {
                      setIsMicOn(false);
                      singleSpeaker
                        ? setIsSingleListening(false)
                        : setIsMultiListening(false);
                      setSessionNotification(true);
                      setSpeakerData("");
                    }}
                    style={
                      window.innerWidth < 350
                        ? { padding: "0.5rem" }
                        : { paddingTop: "0.5rem", paddingBottom: "0.5rem" }
                    }
                  >
                    <h1
                      style={
                        window.innerWidth < 350
                          ? { fontSize: "xx-small", fontWeight: "bold" }
                          : { fontSize: "inherit" }
                      }
                    >
                      <StopIcon />
                    </h1>
                  </button>
                </div>
                <Button
                  id="mute-button"
                  icon={isMicOn ? <AudioOutlined /> : <AudioMutedOutlined />}
                  disabled={!ready || loading}
                  onClick={
                    singleSpeaker ? toggleSingleListening : toggleMultiListening
                  }
                  className="inline-flex items-center rounded-full border border-transparent text-2xl bg-gray-600 p-2 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                />
              </div>
            </>
          ) : (
            <Button
              className="inline-flex items-center rounded-full border border-transparent text-md bg-gray-600 p-2 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
			  onClick={() => {
				{currentUser?.id === undefined ? navigate("/") : navigate('/home')}
			  }}
            >
              Leave
            </Button>
          )}

          <div
            className="flex flex-row md:space-x-4 items-center justify-center"
            style={{ paddingLeft: "0.25rem", paddingRight: "0.25rem" }}
          >
            {/* Invite button */}
            <button
              id="invite-button"
              onClick={() => setInvite(true)}
              className=" w-full"
            >
              <p
                className="inline-flex items-center p-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                style={
                  window.innerWidth < 376
                    ? {
                        fontSize: "xx-small",
                        fontWeight: "bold",
                        padding: "0.5rem",
                        marginRight: "10px",
                      }
                    : window.innerWidth < 768
                    ? {
                        marginRight: "10px",
                      }
                    : {
                        fontSize: "medium",
                      }
                }
              >
                <SendIcon />
              </p>
            </button>

            <button
              id="settings-button"
              className={`inline-flex items-center rounded-full mr-2 border border-transparent bg-gray-600 p-2 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
              onClick={handleSettingsToggle}
            >
              <SettingsRoundedIcon />
            </button>

            {planType === "basic" ? null : (
              <button
                className={`${noNoteSectionView} inline-flex items-center rounded-full border border-transparent bg-gray-600 p-2 text-white shadow-sm mr-2 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
                onClick={() => {
                  setNoteSection(!noteSection);
                }}
                style={
                  window.innerWidth < 350
                    ? {
                        marginLeft: "0.25rem",
                      }
                    : { marginLeft: ".5rem" }
                }
              >
                <TextSnippetIcon />
              </button>
            )}
            {!isFullScreen && (
              <button
                id="fullScreen-button"
                className="inline-flex items-center rounded-full border border-transparent bg-gray-600 p-2 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                onClick={toggleFullScreen}
              >
                <OpenInFullRoundedIcon />
              </button>
            )}
          </div>
        </div>
        {isSettingsOpen && (
          <div
            className="bg-white p-4 rounded-lg shadow-xl mt-2"
            style={
              window.innerWidth < 487
                ? {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 70,
                    right: noteSection ? 200 : 10,
                    paddingRight: 40,
                    zIndex: 9999,
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
                  }
                : window.innerWidth >= 487 && window.innerWidth < 1200
                ? {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 70,
                    right: noteSection ? 325 : 10,
                    paddingRight: 40,
                    zIndex: 9999,
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
                  }
                : {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 70,
                    right: noteSection ? 475 : 10,
                    paddingRight: 40,
                    zIndex: 9999,
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
                  }
            }
          >
            {singleSpeaker === true ? null : (
              <>
                <CaptionBubbleColor />
                <div
                  style={{
                    width: "100%",
                    margin: "16px 0",
                    borderTop: "1px solid black",
                    marginLeft: 30,
                  }}
                ></div>
              </>
            )}
            <FontSizeScale />
          </div>
        )}

        {/* Results - auto Scroll to bottom */}
        <div
          className={`relative w-full h-screen overflow-y-auto scroll-smooth ${
            isFullScreen ? "full-screen" : ""
          }`}
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 16,
              width: "100%",
              zIndex: 9999,
              backgroundColor: "#FFFFFF",
            }}
          >
            {isFullScreen && (
              <div className="absolute right-0 top-2 flex">
                {isAdmin && (
                  <Button
                    id="mute-button"
                    icon={isMicOn ? <AudioOutlined /> : <AudioMutedOutlined />}
                    disabled={!ready || loading}
                    onClick={
                      singleSpeaker
                        ? toggleSingleListening
                        : toggleMultiListening
                    }
                    className="inline-flex items-center mr-2 justify-center rounded-full border border-transparent text-2xl bg-gray-600 p-2 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  ></Button>
                )}
                {!singleSpeaker ? (
                  <button
                    className={`inline-flex items-center rounded-full mr-2 border border-transparent bg-gray-600 p-2 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mr-2`}
                    onClick={handleSettingsToggle}
                  >
                    <SettingsRoundedIcon />
                  </button>
                ) : null}
                {isSettingsOpen && (
                  <div
                    className="bg-white p-4 rounded-lg shadow-xl mt-2"
                    style={
                      window.innerWidth < 487
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "fixed",
                            top: 70,
                            right: noteSection ? 200 : 10,
                            paddingRight: 40,
                            zIndex: 9999,
                            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
                          }
                        : window.innerWidth >= 487 && window.innerWidth < 1200
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "fixed",
                            top: 70,
                            right: noteSection ? 325 : 10,
                            paddingRight: 40,
                            zIndex: 9999,
                            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
                          }
                        : {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "fixed",
                            top: 70,
                            right: noteSection ? 475 : 10,
                            paddingRight: 40,
                            zIndex: 9999,
                            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
                          }
                    }
                  >
                    {singleSpeaker === true ? null : (
                      <>
                        <CaptionBubbleColor />

                        <div
                          style={{
                            width: "100%",
                            margin: "16px 0",
                            borderTop: "1px solid black",
                            marginLeft: 30,
                          }}
                        ></div>
                      </>
                    )}
                    <FontSizeScale />
                  </div>
                )}
                <button
                  className="inline-flex items-center rounded-full border border-transparent bg-gray-600 p-2 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mr-2"
                  onClick={toggleFullScreen}
                >
                  <CloseFullscreenRoundedIcon />
                </button>
              </div>
            )}
          </div>

          <div className="relative">
            <div className="flex">
              {SessionResultContainer(
                bgColorEnabled,
                messages,
                isAdmin,
                singleSpeaker,
                isFullScreen,
                interimTranscript,
                uuid,
                currentUser
              )}
            </div>
            {isFullScreen ? (
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 5,
                  margin: "10px",
                  color: "#7D7D7D",
                  fontFamily: "Verdana, sans-serif",
                  fontStyle: "italic",
                }}
              >
                <p>Koda &copy;</p>
                <small>Powered by Imanyco</small>
              </div>
            ) : (
              <div>
                {inSession ? null : (
                  <button
                    onClick={() => setIsTourOpen(true)}
                    style={{
                      position: "fixed",
                      bottom: window.innerWidth > 766 ? 10 : 70,
                      right: window.innerWidth > 766 ? 10 : 15,
                    }}
                    className="inline-flex items-center p-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    <QuestionMarkOutlinedIcon />
                  </button>
                )}
              </div>
            )}
          </div>
          <div ref={messagesEndRef} />
        </div>

        {/* Speaker Start Modal */}
        <Transition.Root show={showMultipleMax} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setShowMultipleMax}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShowMultipleMax(false);
                          submitSessionTime();
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left justify-center m-2">
                      <div className="text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-medium leading-6 text-gray-900 border-b-2 border-gray-300 m-2 pb-4"
                        >
                          No Multi-Speaker Time Remaining
                        </Dialog.Title>
                      </div>

                      <div className="mt-2 flex-col lg:flex-row p-6 space-y-2 items-center justify-center text-center">
                        You have reached the limit for multiple speaker sessions
                        this month. Please feel free to continue to enjoy single
                        speaker sessions until the end of the month or upgrade
                        now.
                      </div>
                      <center>
                        <Link to="/account">
                          <button
                            className={`rounded-2xl space-x-4 px-4 py-2 border font-bold leading-tight shadow-sm text-white bg-black hover:bg-gray-700 hover:shadow-lg focus:bg-gray-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}
                          >
                            Upgrade Now
                          </button>
                        </Link>
                      </center>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Speaker Start Modal */}
        <Transition.Root show={showSpeakerSelection} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setShowSpeakerSelection}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left justify-center m-2">
                      <div className="text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-medium leading-6 text-gray-900 border-b-2 border-gray-300 m-2 pb-4"
                        >
                          How Many Speakers?
                        </Dialog.Title>
                      </div>

                      <div className="mt-2 flex-col lg:flex-row p-6 space-y-2 items-center justify-center text-center">
                        <div className="flex flex-col lg:flex-row sm:space-x-4 space-y-2 items-center w-full justify-center">
                          <button
                            onClick={() => {
                              handleClickSingleSpeaker();
                            }}
                            className="flex flex-col items-center"
                            style={{ padding: "10px 20px", margin: "0 5px" }}
                          >
                            <UserIcon className="h-6 w-6" aria-hidden="true" />
                            <div className="mt-2 text-center">
                              <h1>Single Speaker</h1>
                            </div>
                          </button>
                          {isDiarizationTimeMax ? (
                            <button
                              onClick={() => {
                                setShowMultipleMax(true);
                              }}
                              className="flex flex-col items-center"
                              style={{ padding: "10px 20px", margin: "0 5px" }}
                            >
                              <UserGroupIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                              <div className="mt-2 text-center">
                                <h1>Multiple Speakers</h1>
                              </div>
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                handleClickMultipleSpeakers();
                              }}
                              className="flex flex-col items-center"
                              style={{ padding: "10px 20px", margin: "0 5px" }}
                            >
                              <UserGroupIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                              <div className="mt-2 text-center">
                                <h1>Multiple Speakers</h1>
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Invite Modal */}
        <Transition.Root show={invite} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setInvite}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-2"
                    style={{ marginBottom: "5rem" }}
                  >
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setInvite(false);
                          setCopied(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    {isRoomMax ? (
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left justify-center m-2">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-medium leading-6 text-gray-900 justify-center border-b-2 border-gray-300 m-2 pb-4"
                        >
                          You've reached your invite limit...
                        </Dialog.Title>
                        {planType === "basic" ? (
                          <>
                            <h1 className="text-md text-center font-medium leading-6 text-gray-600 justify-center">
                              The invite limit for the <b>{planType} plan</b> is{" "}
                              {maxSpeakers - 1} people. Please upgrade now if
                              you want to invite more people.
                            </h1>
                            <center>
                              <Link to="/account">
                                <button
                                  className={`rounded-2xl space-x-4 px-4 py-2 border font-bold leading-tight shadow-sm text-white bg-black hover:bg-gray-700 hover:shadow-lg focus:bg-gray-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}
                                >
                                  Upgrade Now
                                </button>
                              </Link>
                            </center>
                          </>
                        ) : (
                          <>
                            <h1 className="text-md text-center font-medium leading-6 text-gray-600 justify-center">
                              The invite limit for the <b>{planType} plan</b> is{" "}
                              {maxSpeakers - 1} people.
                            </h1>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left justify-center m-2">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-medium leading-6 text-gray-900 justify-center border-b-2 border-gray-300 m-2 pb-4"
                        >
                          Invite a friend
                        </Dialog.Title>
                        <h1 className="text-md text-center font-medium leading-6 text-gray-600 justify-center">
                          Scan this QR code.
                        </h1>
                        <div className="mt-2 flex-col lg:flex-row p-6 pt-4 space-y-2 items-center justify-center text-center">
                          <div className="flex flex-col lg:flex-row sm:space-x-4 p-10 pt-0 pb-2 space-y-2 items-center w-full">
                            <div
                              style={{
                                height: "auto",
                                margin: "0 auto",
                                width: "100%",
                              }}
                            >
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                }}
                                value={"https://koda.imanyco.com/" + roomUUID}
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                          </div>
                          <Divider
                            className="text-md text-center font-medium leading-6 text-gray-600 justify-center"
                            sx={{
                              "&::before, &::after": {
                                borderColor: "secondary.light",
                              },
                            }}
                          >
                            or you can copy and share the link below
                          </Divider>

                          <div className=" flex flex-row justify-evenly items-center space-x-2 text-center">
                            {/* Copied Link Value */}
                            <div className="flex flex-row justify-center items-center text-center space-x-2">
                              <TextField
                                autoFocus={false}
                                disableRipple
                                disableFocusRipple
                                fullWidth
                                className="w-full px-2 py-4 rounded-lg"
                                placeholder="Link"
                                id="outlined-link-input"
                                type="text"
                                inputProps={{ style: { fontWeight: "bold" } }}
                                value={"https://koda.imanyco.com/" + roomUUID}
                                readOnly
                              />

                              <button
                                className="bg-gray-300 hover:bg-gray-400 active:bg-gray-500 text-black font-bold p-4 rounded-full"
                                onClick={handleCopyLink}
                              >
                                {copiedIcon}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Session Pause/End Modal */}
        <Transition.Root show={sessionNotification} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setSessionNotification}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div
                className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0"
                style={{ alignItems: "center" }}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    {isSessionTimeout ? null : (
                      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                        <button
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => {
                            setSessionNotification(false);
                            setIsMicOn(true);
                            singleSpeaker
                              ? toggleSingleListening()
                              : toggleMultiListening();
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    )}
                    <div className="flex sm:flex sm:items-start">
                      {isSessionTimeout ? (
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-xl font-medium leading-6 text-gray-900 justify-center border-b-2 border-gray-300 m-2 pb-4"
                          >
                            Your current session time has run out...
                          </Dialog.Title>
                          <div className="mt-2">
                            <p
                              className="text-sm text-gray-500"
                              style={{ textAlign: "justify", padding: "8px" }}
                            >
                              Press "Save Session" to keep your session. Press
                              "Discard" to delete your session.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-xl font-medium leading-6 text-gray-900 justify-center border-b-2 border-gray-300 m-2 pb-4"
                          >
                            Save your current session?
                          </Dialog.Title>
                          <div className="mt-2">
                            <p
                              className="text-sm text-gray-500"
                              style={{ textAlign: "justify", padding: "8px" }}
                            >
                              Press "Save Session" to keep your session. Press
                              "Discard" to delete your session, or exit this
                              modal window to return to the current session.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      {isAdmin ? (
                        <Button
                          size="small"
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          style={{
                            padding: "1em 2em",
                            border: 0,
                            borderRadius: "30px",
                            color: "black",
                            backgroundColor: "#D8F55A",
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                          }}
                          onClick={() => {
                            setSessionNotification(false);
                            setSaveSession(true);
                          }}
                        >
                          Save Session
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          style={{
                            padding: "1em 2em",
                            border: 0,
                            borderRadius: "30px",
                            color: "black",
                            backgroundColor: "#D8F55A",
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                          }}
                          onClick={() => {
                            navigate("/home");
                          }}
                        >
                          Save Session
                        </Button>
                      )}
                      <Button
                        size="small"
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        style={{
                          padding: "1em 2em",
                          border: "1px solid black",
                          borderRadius: "30px",
                          color: "black",
                          backgroundColor: "white",
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                        }}
                        onClick={(e) => {
                          setSessionNotification(false);
                          submitSessionTime();
                          setReady(false);
                          handleDeleteSession(currentUser, roomUUID);
                          navigate("/home");
                        }}
                      >
                        Discard
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Session Save Modal */}
        <Transition.Root show={saveSession} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setSaveSession}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setSaveSession(false);
                          setIsMicOn(true);
                          setIsSingleListening(true);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="flex sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Save Your Session
                        </Dialog.Title>
                        <div className="mt-2 flex-col lg:flex-row p-4 space-y-2 items-center">
                          <input
                            className="border rounded-lg p-2"
                            type="text"
                            placeholder="Session Name..."
                            value={sessionName}
                            onChange={(e) => setSessionName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSaveHistory}
                      >
                        Save
                      </button>
                    </div>
                    {<br />}
                    {success ? (
                      <Alert
                        className="text-green-500"
                        variant="filled"
                        severity="success"
                      >
                        {success}
                      </Alert>
                    ) : error ? (
                      <Alert
                        className="text-red-500"
                        variant="filled"
                        severity="error"
                      >
                        {error}
                      </Alert>
                    ) : (
                      " "
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      <div
        className={`${noteSectionView} w-1/2 m-1 bg-gray-100 border rounded-xl`}
      >
        {/* Note Section */}
        <div className={` flex h-full w-full border rounded-xl`}>
          <div className="relative flex flex-col w-full">
            <div className="flex items-center justify-between px-4 py-2">
              <h1 className="font-semibold">Note Section</h1>
              <button
                className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                onClick={() => setNoteSection(false)}
              >
                <VisibilityOffIcon />
              </button>
            </div>
            <NotesEditor
              roomId={roomUUID}
              value={comments}
              userId={currentUser?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recognizer;
