import axios from 'axios'

//registers new user
export const handleRegister = (
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    phoneNumber,
    avatar,
    setError,
    setEmail,
    setPassword,
    dispatch,
    navigate,
    loggedIn
) => (e) => {
    e.preventDefault();
    const userData = {
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        subscription: 'basic',
        signUpMethod: 'local',
        avatar
    };

    if (password !== confirmPassword) {
        setError("Passwords do not match!");
    } else {
        axios
            .post("/api/v1/users/register", userData)
            .then(async (res) => {
                e.preventDefault();
                fetch("/api/v1/users/login", {
                    method: "POST",
                    body: JSON.stringify({
                        email,
                        password,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then((res) => {
                    const data = res.json();
                    if (res.ok) {
                        setEmail("");
                        setPassword("");
                        setError("");
                        dispatch(loggedIn(data));
                        navigate("/home");
                    } else {
                        throw data;
                    }
                });
            })
            .catch((res) => {
                setError("That email already exists");
            });
    }
};

//logs user in
export const handleLogin = (
    email,
    password,
    setError,
    setEmail,
    setPassword,
    dispatch,
    navigate,
    loggedIn
) => (e) => {
    e.preventDefault();
    fetch('/api/v1/users/login', {
        method: 'POST',
        body: JSON.stringify({
            email,
            password,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(async (res) => {
            const data = await res.json();
            if (res.ok) {
                setEmail('');
                setPassword('');
                setError('');
                dispatch(loggedIn(data));
                navigate('/home');
            } else {
                throw data;
            }
        })
        .catch((error) => {
            setError(error.message || 'Something went wrong');
        });
};

// update user profile info
export const handleProfileUpdate = (currentUser, firstName, lastName, phoneNumber, setShow, setError) => (e) => {
    e.preventDefault();
    const userData = { firstName, lastName, phoneNumber };
    axios
        .patch(`/api/v1/users/${currentUser.id}/update-profile`, userData)
        .then((res) => {
            setShow(true);
        })
        .catch((error) => {
            setError(error.response.data.message);
        });
};

//update user email info
export const handleEmailUpdate = (currentUser, email, setShow, setError) => (e) => {
    e.preventDefault();
    const userEmail = { email };
    axios
        .patch(`/api/v1/users/${currentUser.id}/update-email`, userEmail)
        .then((res) => {
            setShow(true);
        })
        .catch((error) => {
            setError(error.response.data.message);
        });
};

	//send forgot password email
	export const sendForgotPasswordEmail = (setIsReset, email, setEmail, navigate) => async (e) => {
		e.preventDefault();
		setIsReset(true);
	  
		try {
		  const response = await fetch('/api/v1/users/forgotPassword', {
			method: 'POST',
			body: JSON.stringify({
			  email,
			}),
			headers: {
			  'Content-Type': 'application/json',
			},
		  });
	  
		  if (response.ok) {
			setEmail('');
			setTimeout(() => {
			  setIsReset(false);
			  navigate('/login');
			}, 4000); // delay for 4 seconds (4000 milliseconds)
		  } else {
			const data = await response.json();
			throw data;
		  }
		} catch (error) {
		  console.log(error); // or show an error message to the user
		}
	  };

//update user password info
export const handlePasswordUpdate = (userId, password, setError, confirmPassword) => (e) => {
    const userPassword = { password };
    if (password !== confirmPassword) {
        setError('Passwords do not match!');
    } else {
    axios
        .patch(`/api/v1/users/${userId}/update-password`, userPassword)
        .then((res) => {
        })
        .catch((error) => {
            setError(error.response.data.message);
        });
    }
};

//checks password requirements
export const checkPassword = (password, setIsPasswordValid) => {
    setIsPasswordValid(
        password.length >= 6 &&
        /(?=.*[a-z])/.test(password) &&
        /(?=.*[A-Z])/.test(password) &&
        /(?=.*\d)/.test(password) &&
        /(?=.*\W)/.test(password)
    );
};