const initialState = {
    currentFontSizeIndex: 0,
    currentColorPalette: 'standard',
    fontSizes: ['16px', '32px', '48px', '64px', '72px', '80px'],
    colorPalettes: {
      standard: ['#FFEB3B', '#FF80AB', '#FF9800', '#40C4FF', '#888DF2', '#0CC672', '#00000'],
      dark: ['#960031', '#0052A1', '#1C006B', '#62006B', '#AD005C', '#02594C', '#FFFFFF'],
      light: ['#FECD8C', '#A2D0FD', '#FFD1DF', '#938DFB', '#FBFDA2', '#5CC2B4', '#000000'],
      neutral: ['#FAFAFA', '#F5F5F5', '#E5E5E5', '#D4D4D4', '#A3A3A3', '#959595', '#000000'],
      none: [],
    },
  };
  
  const FONT_SIZE_INCREASED = 'FONT_SIZE_INCREASED';
  const FONT_SIZE_DECREASED = 'FONT_SIZE_DECREASED';
  const COLOR_PALETTE_SELECTION = 'COLOR_PALETTE_SELECTION';
  
  export const increaseFontSize = () => ({
    type: FONT_SIZE_INCREASED,
  });
  
  export const decreaseFontSize = () => ({
    type: FONT_SIZE_DECREASED,
  });
  
  export const chooseColorPalette = (paletteName) => ({
    type: COLOR_PALETTE_SELECTION,
    payload: paletteName,
  });
  
  export const accessibilityReducer = (state = initialState, action) => {
    switch (action.type) {
      case FONT_SIZE_INCREASED:
        return {
          ...state,
          currentFontSizeIndex: Math.min(state.currentFontSizeIndex + 1, state.fontSizes.length - 1),
        };
      case FONT_SIZE_DECREASED:
        return {
          ...state,
          currentFontSizeIndex: Math.max(state.currentFontSizeIndex - 1, 0),
        };
      case COLOR_PALETTE_SELECTION:
        return {
          ...state,
          currentColorPalette: action.payload,
        };
      default:
        return state;
    }
  };
  