import { combineReducers } from 'redux'
import { userReducer } from './userReducer'
import { conversationReducer } from './conversationReducer'
import { accessibilityReducer } from './accessibilityReducer'
import { historyReducer } from './historyReducer';


const rootReducer = combineReducers({
  user: userReducer,
  conversation: conversationReducer,
  accessibility: accessibilityReducer,
  history: historyReducer
})

export default rootReducer