import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Tour from 'reactour'
import axios from 'axios';
import { Button } from 'antd';
import HistoryContainer from './HistoryContainer';
import { useDispatch, useSelector } from 'react-redux';
// Notification for Session
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
	XMarkIcon,
	QrCodeIcon,
} from '@heroicons/react/24/outline';
// QR reader
import QrReader from 'react-web-qr-reader';
import home_image from '../../assets/home_image.png';
import Koda_logo_black from '../../assets/new_koda_logo_black.jpg';

import Mixpanel from 'mixpanel-browser';
import SubscriptionSettings from '../Subscription/SubscriptionSettings';
Mixpanel.init('50158065c2effca4d574cb213ffde886', {debug: true, ignore_dnt: true});


function DashboardContainer({
	currentUser,
}) {

	const { createRoom, addParticipant } = require('../../utilities/conversation');
	const [userTime, setUserTime] = useState(0);
	const [roomName, setRoomName] = useState('');
	const navigate = useNavigate();
	const [displayScanner, setDisplayScanner] = useState(false);
	const [qrData, setQrData] = useState('No Result');
	const displayScannerView = displayScanner ? 'block' : 'hidden';
	const displayJoinOptions = displayScanner ? 'hidden' : 'block';
	const dispatch = useDispatch();
	const delay = 500;
	const previewStyle = {
		height: 240,
		width: 320,
	};
	const [premUser, setPremUser] = useState(false);
	// Notification for Session
	const [joinSession, setJoinSession] = useState(false);
	const [isTourOpen, setIsTourOpen] = useState(false);

	useEffect(() => {
		// Check if the tour has already been started
		const hasTourStarted = localStorage.getItem('dashboardTourStarted');
	
		if (!hasTourStarted) {
		  // Start the tour if it hasn't been started before
		  setIsTourOpen(true);
		  localStorage.setItem('dashboardTourStarted', 'true');
		}
	  }, []);

	  const tourSteps = [
		{
		  selector: '#start-session',
		  content: 'Click here to create a new session',
		},
		{
		  selector: '#join-session',
		  content: 'Click here to join someone else’s session',
		},
		{
			selector: '#history-container',
			content: 'View your past sessions here',
		  },
	  ];

	  const closeTour = () => {
		setIsTourOpen(false);
	  };

useEffect(() => {
	//check if user is a paid
	setPremUser(currentUser.subscriptionId !== null);
	// reset selected history state
	dispatch({ type: 'SET_SESSION_SELECTION', payload: false });
	dispatch({ type: 'SET_VIEW_SESSION_MENU', payload: true });
  }, [currentUser.subscriptionId]);
  
  //QR scanner
  const handleScan = (data) => {
	if (data) {
	  setQrData(data.data);
	  // navigate to https: in qr code
	  window.location.href = data.data;
	} else {
	  setQrData('No result');
	}
  };
  
  //error handler
  const handleError = (error) => { };
  
  //room name input for "Join Session"
  const handleRoomNameChange = ({ target: { value } }) => {
	setRoomName(value);
  };
  
  //get user session times
  useEffect(() => {
	axios.get(`/api/v1/session-times`)
	  .then((res) => {
		setUserTime(res?.data?.[0]?.duration || 0);
	  })
	  .catch((err) => console.log(err));
  }, [userTime]);

	return (
		<div className={` h-full`}>
			      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        // onAfterOpen={handleTourStepChange}
      />
			<div
				className='flex flex-col mx-auto pb-24 lg:pb-8 lg:flex-row'
				style={{ overflowX: 'hidden', maxWidth: '100%' }}>
				<div className='mx-4 lg:mx-auto max-w-7xl sm:px-4 lg:w-2/3'>
					<div className='flex flex-col w-full'>
						<div className='flex flex-col sm:flex-row'>
							<div className='flex flex-col space-y-4 w-full sm:w-2/3 py-4 2xl:space-y-8 lg:py-8'>
								{window.innerWidth < 768 && (
									<div
										className='sm:flex flex-col self-end justify-center items-center w-full sm:w-1/3 pl-4 py-4 xl:pl-12 xl:py-12'
										style={
											window.innerWidth > 639
												? null
												: { textAlign: '-webkit-center' }
										}>
										<Link to='/home'>
											<img
												src={ Koda_logo_black}
												alt='logo'
												className='logo'
												style={
													window.innerWidth > 639
														? {
															width: '50%',
															padding: '4px',
														}
														: {
															width: '20%',
															padding: '4px',
														}
												}
											/>
										</Link>
									</div>
								)}
								<h1 className='p-2'>Hello {currentUser.firstName}!</h1>
								<h1 className='text-3xl xl:text-4xl '>
									Discover what
									<br />
									you've been missing!
								</h1>
								<div className='flex flex-row w-full space-x-2'>
									<button
									id='start-session'
									onClick={(e) => {
										createRoom(e, currentUser).then((roomId) => {
											navigate(`/${roomId}`);
										});
									}}
										className='inline-flex  items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-black bg-button-primary hover:bg-button-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'>
										Create Session
									</button>

									<button
									id='join-session'
										onClick={() => setJoinSession(true)}
										className='inline-flex items-center px-5 py-2 border border-white text-base font-medium rounded-full shadow-sm text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
										Join Session
									</button>
								</div>
							</div>
							{/* Demo Img */}
							<div className='sm:flex flex-col self-center justify-center items-center w-full sm:w-1/3 pl-4 py-4 xl:pl-12 xl:py-12'>
								{window.innerWidth > 767 && (
									<img src={home_image} alt='demo' className='rounded-xl' />
								)}
							</div>
						</div>
					</div>
					<div
						className={` overflow-hidden rounded-2xl border shadow items-center`}>
						<div 
						id='history-container'
						className='overflow-y-auto'>
							<HistoryContainer />
						</div>
						<div className='flex flex-col border px-4 py-4 sm:px-6 justify-center text-center items-center'>
							<button
								onClick={() => {
									Mixpanel.track('Navigation to History Page', {
										'distinct_id': currentUser.id
									});
								}}
								className={`rounded-2xl flex flex-row space-x-4 px-4 py-2 border font-bold leading-tight shadow-sm  bg-button-secondary hover:bg-button-secondary-hover hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}>
								<Link to='/history'>View History</Link>
							</button>
						</div>
					</div>
				</div>

				<div
					className={` lg:block rounded-xl mx-auto max-w-7xl  sm:px-6 lg:px-8 items-center justify-center text-center lg:w-1/3 h-full w-full`}>
					<div className='flex flex-col space-y-4 m-4 items-center'>
					</div>
					<div className='flex flex-col space-y-4 items-center overflow-hidden'>
						<SubscriptionSettings />
					</div>
					<div className='flex flex-col px-4 py-4 sm:px-6 mr-8 justify-center text-center items-center'>
					{window.innerWidth > 1023 && (<button
								onClick={() => {
									setIsTourOpen(true);
								}}
								className={`rounded-2xl flex flex-row space-x-4 px-4 py-2 border font-bold leading-tight shadow-sm  bg-button-secondary hover:bg-button-secondary-hover hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}>
								Want to view the tutorial again?
							</button>
					)}
						</div>
				</div>
			</div>

			{/* Join Session Modal */}
			<Transition.Root show={joinSession} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={setJoinSession}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 overflow-y-auto'>
						<div
							className='flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0'
							style={{ alignItems: 'center' }}>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
								<Dialog.Panel className='relative transform overflow-hidden rounded-2xl bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
									<div className='flex sm:flex sm:items-start'>
										<div className='absolute top-0 right-0 pt-4 pr-4 sm:block'>
											<button
												type='button'
												className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
												onClick={() => {
													setJoinSession(false);
													setDisplayScanner(false);
												}}>
												<span className='sr-only'>Close</span>
												<XMarkIcon className='h-6 w-6' aria-hidden='true' />
											</button>
										</div>
										<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full'>
											<Dialog.Title
												as='h3'
												className={`${displayJoinOptions} text-xl font-bold leading-6 text-gray-900 justify-center border-b-2 border-gray-300 m-2 pb-4`}>
												Join Session
											</Dialog.Title>
											<div className='mt-2 flex-col lg:flex-row p-4 space-y-2 text-left w-full'>
												<div
													className={`${displayJoinOptions} flex flex-col w-full `}>
													<input
														type='text'
														placeholder='Session Link'
														value={roomName}
														onChange={handleRoomNameChange}
														className='mt-1 block w-full rounded-2xl border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm w-full'
													/>
												</div>

												{/* Button to scan QR code */}

												<div
													className={` ${displayScannerView} flex flex-col items-center justify-center pb-24`}>
													{' '}
													<h1>Scan Your QR Code Below:</h1>
													{/* Display qrScanner */}
													{displayScannerView === 'block' && (
														<QrReader
															delay={delay}
															style={previewStyle}
															onError={handleError}
															onScan={handleScan}
														/>
													)}
												</div>
											</div>
										</div>
									</div>
									<div
										className={`${displayJoinOptions}`}
										style={{ textAlign: 'justify', padding: '8px' }}>
										<div
											className={`justify-between mt-5 space-y-4 sm:space-y-0 sm:mt-4 sm:flex sm:flex-row-reverse`}
											style={{ alignItems: 'center' }}>
											
												<Button
													size='small'
													type='button'
													className='inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
													style={{
														padding: '1em 2em',
														border: 0,
														borderRadius: '30px',
														color: 'black',
														backgroundColor: '#D8F55A',
														textAlign: 'center',
														fontSize: '12px',
														fontWeight: 'bold',
														fontFamily: 'sans-serif',
													}}
													onClick={() => {
														addParticipant(currentUser, roomName);
														window.location.href = roomName
													  }}>
													Join Room
												</Button>
										
											<Button
												size='small'
												type='button'
												className='inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
												style={{
													padding: '1em 2em',
													border: 0,
													borderRadius: '30px',
													color: 'white',
													backgroundColor: 'black',
													textAlign: 'center',
													fontSize: '12px',
													fontWeight: 'bold',
													fontFamily: 'sans-serif',
												}}
												onClick={() => setDisplayScanner(true)}>
												<div className='flex flex-row space-x-2 items-center'>
													<QrCodeIcon className='h-6 w-6' aria-hidden='true' />
													<p>Scan QR Code</p>
												</div>
											</Button>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
}
export default DashboardContainer;
