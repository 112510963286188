import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
// Image Imports
import koda_sample from '../assets/koda_sample.png';
import landingPageHeroImg from '../assets/landingPageHeroImg.png';
import laptop_demo from '../assets/laptop_demo.jpg';

// Icons
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

// Logo
import koda_logo_black from '../assets/koda_logo_black.png';
import ReactGA from 'react-ga';
import SubscriptionSettings from '../components/Subscription/SubscriptionSettings';

function LandingPage() {
	const currentUser = useSelector((state) => state.user.currentUser);
	const footerNavigation = {
		support: [
			{
				name: 'koda@imanyco.com',
				href: `mailto:kodat@imanyco.com?subject=Support%20Request`,
			},
		],
		company: [
			{ name: 'Imanyco', href: 'https://imanyco.com/about/' },
			{ name: 'Blog', href: 'https://imanyco.com/blog/' },
		],
		social: [
			{
				name: 'Facebook',
				href: 'https://www.facebook.com/Koda.imanyco',
				icon: (props) => (
					<svg fill='currentColor' viewBox='0 0 24 24' {...props}>
						<path
							fillRule='evenodd'
							d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
							clipRule='evenodd'
						/>
					</svg>
				),
			},
			{
				name: 'LinkedIn',
				href: 'https://www.linkedin.com/showcase/kodaimanyco',
				icon: (props) => (
					// LinkedIn icon
					<LinkedInIcon />
				),
			},
		],
	};

	//google analytics
	ReactGA.initialize('UA-189074005-1');
	ReactGA.pageview('/');

	return (
		<>
			<Helmet>
        <title>Imanyco Koda - Real-time Transcription App</title>
      </Helmet>
			<div
				className='flex flex-col'

			>
				{/* Hero Section Option 1 */}
				<div className='grid grid-cols-1 gap-y-8 gap-x-4 m-4 justify-items-center text-center items-center'>
					<div className='flex flex-col md:flex-row justify-evenly w-full pt-12 px-8 bg-gray-200 items-center space-y-6 md:space-y-0 md:space-x-4 rounded-t-3xl rounded-br-3xl'>
						<div className='flex flex-col items-center md:items-start text-center md:text-left space-y-4 lg:space-y-8 pb-2'>
							<h1 className='text-3xl md:text-4xl lg:text-5xl font-bold'>
								Discover what you've <br /> been missing
							</h1>
							<h2>
								Koda is the most convenient live transcription app for <br />
								the deaf and hard of hearing
							</h2>

							<Link to={currentUser ? '/home' : '/signup'}>
								<button className='text-black bg-button-primary hover:bg-button-primary-hover font-bold py-2 px-4 rounded-full'>
									Get Started With Koda
								</button>
							</Link>
						</div>
						<img
							className='w-1/3'
							src={landingPageHeroImg}
							alt='landingPageImage'
						/>
					</div>
				</div>

				{/* Product Section */}
				<div className=' p-4 space-y-12'>
					<div className='grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-4 m-4 justify-items-center text-center items-center'>
						<div className='flex flex-col space-y-6 m-4 items-start'>
							<h1 className='text-2xl md:text-3xl font-semibold text-left'>
								Koda makes conversations <br /> simple and inclusive
							</h1>
							<p className='text-sm md:text-md lg:text-lg text-left leading-5'>
								During a conversation, Koda transcribes the words of each
								individual speaker so that you know exactly who is saying what.
								Since it’s a web-based application, you can start using it
								immediately on any device that is connected to the internet,
								without needing to download anything beforehand.
							</p>
							<Link to={currentUser ? '/home' : '/signup'}>
								<button className='text-white bg-black font-bold py-2 px-4 rounded-full text-sm'>
									Get Started With Koda
								</button>
							</Link>
						</div>

						<div>
							<img src={koda_sample} className='rounded-2xl' alt='Phone Demo' />
						</div>

						<div className='w-full'>
							<img
								className='rounded-t-3xl rounded-bl-3xl'
								src={laptop_demo}
								alt='Laptop Demo'
							/>
						</div>

						<div className='flex flex-col space-y-2 m-4 items-start '>
							<h1 className='text-2xl md:text-3xl font-semibold text-left'>
								Do more with Koda
							</h1>
							<h1 className='text-md md:text-lg font-semibold text-left'>
								Never Miss A Word
							</h1>
							<p className='text-sm md:text-md lg:text-lg text-left leading-5'>
								Koda transcribes and color-codes individual speakers so that
								those who are deaf and hard of hearing know exactly who is
								saying what in real-time.
							</p>
							<h1 className='text-md md:text-lg font-semibold text-left'>
								Review Past Sessions
							</h1>
							<p className='text-sm md:text-md lg:text-lg text-left leading-5'>
								All transcriptions are saved and are accessible so you can
								conveniently view your past conversations whenever you’d like.
							</p>
							<h1 className='text-md md:text-lg font-semibold text-left'>
								Share Your Messages
							</h1>
							<p className='text-sm md:text-md lg:text-lg text-left leading-5'>
								Your sessions can easily be shared with other users so your
								friends or team are always up to date.
							</p>
							<Link to={currentUser ? '/home' : '/signup'}>
								<button className='mt-2 text-black bg-button-primary hover:bg-button-primary-hover font-bold py-2 px-4 rounded-full text-sm'>
									Get Koda
								</button>
							</Link>
						</div>

						<div className='flex flex-col space-y-6 m-4 items-start'>
							<h1 className='text-2xl md:text-3xl font-semibold text-left'>
								Taking accessibility further
							</h1>
							<p className='text-sm md:text-md lg:text-lg text-left leading-5'>
								We believe that communication should be inclusive and accessible
								for everyone, regardless of a disability. Communication should
								be simple because we all deserve to feel connected to the world
								around us. That’s why we created Koda. Check out the video to
								learn more about why we do what we do!
							</p>
							<Link to={currentUser ? '/home' : '/signup'}>
								<button className='text-white bg-black font-bold py-2 px-4 rounded-full text-sm'>
									See What Koda Can Do
								</button>
							</Link>
						</div>

						<div className='flex justify-center items-center w-3/4 mx-auto'>
							<iframe
								style={{
									borderTopLeftRadius: '30px',
									borderTopRightRadius: '30px',
									borderBottomRightRadius: '30px',
									border: 'none',
									borderRadius: '20px',
									frameBorder: '0',
								}}
								width='560'
								height='315'
								src='https://www.youtube.com/embed/qzmjGSBA8Is'
								title='YouTube video player'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen></iframe>
						</div>
					</div>
					{/*Pricing Section */}
<div className='relative'>
<h1 className='mb-6 text-2xl font-bold text-center'>Compare Plans</h1>
  <SubscriptionSettings />
</div>

				</div>

				{/* Footer Section */}
				<footer className='bg-gray-50' aria-labelledby='footer-heading'>
					<h2 id='footer-heading' className='sr-only'>
						Footer
					</h2>
					<div className='mx-auto max-w-7xl px-4 pt-16 pb-8 sm:px-6 lg:px-8 lg:pt-24'>
						<div className='xl:grid xl:gap-8 '>
							<div className='grid md:grid-cols-2 gap-12 '>
								<img
									className='h-20 mt-12 md:mt-0'
									src={koda_logo_black}
									alt='Koda Logo'
								/>
								<div className='grid grid-cols-3 gap-24 lg:gap-12'>
									<div className='mt-12 md:mt-0'>
										<h3 className='font-medium text-gray-900'>Support</h3>
										<ul className='mt-4 space-y-4 text-sm'>
											{footerNavigation.support.map((item) => (
												<li key={item.name}>
													<a
														href={item.href}
														className=' text-gray-500 hover:text-gray-900'>
														{item.name}
													</a>
												</li>
											))}
										</ul>
									</div>
									<div className='mt-12 md:mt-0'>
										<h3 className='font-medium text-gray-900'>Company</h3>
										<ul className='mt-4 space-y-4 text-sm'>
											{footerNavigation.company.map((item) => (
												<li key={item.name}>
													<a
														href='https://imanyco.com/about/'
														className=' text-gray-500 hover:text-gray-900'>
														{item.name}
													</a>
												</li>
											))}
										</ul>
									</div>
									<div className='mt-12 md:mt-0'>
										<h3 className='font-medium text-gray-900'>Follow Us</h3>
										<div className='flex space-x-6 md:order-2 mt-4'>
											{footerNavigation.social.map((item) => (
												<a
													key={item.name}
													href={item.href}
													className='text-gray-400 hover:text-gray-500'>
													<span className='sr-only'>{item.name}</span>
													<item.icon className='h-6 w-6' aria-hidden='true' />
												</a>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16'>
							<div className='mt-8 text-base text-gray-400 md:order-1 md:mt-0 flex flex-row space-x-4'>
								<p>&copy; 2023 Imanyco, Inc. All rights reserved.</p>
								<a href='https://www.imanyco.com/privacy-terms'>
									<p className='text-base text-gray-500 hover:text-gray-900'>
										Privacy and Terms
									</p>
								</a>
							</div>
						</div>
					</div>
				</footer>
			</div>
		</>
	);
}

export default LandingPage;
