import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

const NEW_CHAT_MESSAGE_EVENT = "newChatMessage";
const SOCKET_SERVER_URL = "koda.imanyco.com";

const ChatSocket = (roomId) => {
  const [messages, setMessages] = useState([]);
  const socketRef = useRef();

  useEffect(() => {
    console.log("Connecting to WebSocket server...");
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { roomId },
      transports: ["websocket"],
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socketRef.current.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, (message) => {
      const incomingMessage = {
        ...message,
        ownedByCurrentUser: message.senderId === socketRef.current.id,
      };
      setMessages((messages) => [...messages, incomingMessage]);
    });

    return () => {
      console.log("Disconnecting from WebSocket server...");
      socketRef.current.disconnect();
    };
  }, [roomId]);

  const sendMessage = (messageBody) => {
    if (messageBody.isInterim === true) {
      if (socketRef.current) {
        socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
          interimText: messageBody.interimTranscript,
          text: '',
          isInterim: true,
          singleSpeaker: messageBody.singleSpeaker,
          senderId: socketRef.current.id,
        });
    
        // Check if socketRef.current.broadcast is defined before calling its methods
        if (socketRef.current.broadcast) {
          socketRef.current.broadcast.emit(NEW_CHAT_MESSAGE_EVENT, {
            interimText: messageBody.interimTranscript,
            text: '',
            singleSpeaker: messageBody.singleSpeaker,
            isInterim: true,
            senderId: socketRef.current.id,
          });
        }
      }
  } else {
    let speakerMessage = messageBody.text;
    

    // Check if socketRef.current is defined before calling its methods
    if (socketRef.current) {
      socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
        text: speakerMessage,
        speaker: messageBody.speaker,
        userSpeakerNames: messageBody.userSpeakerNames,
        singleSpeaker: messageBody.singleSpeaker,
        isInterim: false,
        senderId: socketRef.current.id,
      });
  
      // Check if socketRef.current.broadcast is defined before calling its methods
      if (socketRef.current.broadcast) {
        socketRef.current.broadcast.emit(NEW_CHAT_MESSAGE_EVENT, {
          text: speakerMessage,
          speaker: messageBody.speaker,
          userSpeakerNames: messageBody.userSpeakerNames,
          singleSpeaker: messageBody.singleSpeaker,
          isInterim: false,
          senderId: socketRef.current.id,
        });
      }
    }
  }
  };
  

  return { messages, sendMessage };
};

export default ChatSocket;
