const defaultState = {
  userConversation: [],
  speakerNames: {},
  isEditing: false, // Add the 'isEditing' property to the default state
};

const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
const SET_SPEAKER_NAMES = 'SET_SPEAKER_NAMES';
const UPDATE_SPEAKER_NAME = 'UPDATE_SPEAKER_NAMES'; // New action type
const SET_IS_EDITING = 'SET_IS_EDITING';

export function setIsEditing(isEditing) {
  return {
    type: SET_IS_EDITING,
    isEditing,
  };
}

export function setConversations(conversations) {
  return {
    type: SET_CONVERSATIONS,
    conversations,
  };
}

export function setSpeakerNames(speakerNames) {
  return {
    type: SET_SPEAKER_NAMES,
    speakerNames,
  };
}

// New action creator
export function updateSpeakerName(userId, speakerNumber, newName) {
  return {
    type: UPDATE_SPEAKER_NAME,
    payload: { userId, speakerNumber, newName },
  };
}

export function fetchConversations(dispatch, getState) {
  const state = getState()
  if (!state.user.currentUser) {
    return
  }
  fetch('/api/v1/conversations')
  .then(res => res.json())
  .then(conversations => {
    dispatch(setConversations(conversations || []))
  })
}

export function conversationReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_CONVERSATIONS:
      return {
        ...state,
        userConversation: action.conversations,
      };
    case SET_SPEAKER_NAMES:
      return {
        ...state,
        speakerNames: action.speakerNames,
      };
    case UPDATE_SPEAKER_NAME: // New action handler
    return {
      ...state,
      speakerNames: {
        ...state.speakerNames,
        [action.payload.userId]: {
          ...state.speakerNames[action.payload.userId],
          [action.payload.speakerNumber]: action.payload.newName,
        },
      },
    };
    case SET_IS_EDITING:
      return {
        ...state,
        isEditing: action.isEditing,
      };
    default:
      return state;
  }
}
