import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

import Protect from '../SiteTools/Protect';
import Home from '../../pages/Home';
import Session from '../../pages/Session';
import History from '../../pages/History';
import Account from '../../pages/Account';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword';
import LandingPage from '../../pages/LandingPage';
import Register from '../../pages/Register';
import SignIn from '../../pages/SignIn';

const { createRoom } = require('../../utilities/conversation.js');

function MobileNavigation() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const params = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(() => {
    if (params.pathname === '/login') {
      return 'home';
    } else if (params.pathname === '/history') {
      return 'history';
    } else if (params.pathname === '/session' || params.pathname.length > 25) {
      return 'session';
    } else {
      return params.pathname.replace('/', '');
    }
  });

  const [isSub, setIsSub] = useState(false);

  useEffect(() => {
    // If the user is logged in and the current path is '/', navigate to '/home'
    if (currentUser && params.pathname === '/') {
      navigate('/home');
    }
  }, [currentUser, params.pathname, navigate]);

  const handleSubscription = () => {
    setValue('account');
    setIsSub(true);
  };

  const handleAccount = () => {
    setIsSub(false);
    navigate('/account');
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {params.pathname !== '/' ? (
        <>
          <BottomNavigation
            sx={{
              position: 'fixed',
              width: '100%',
              height: 'auto',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 100,
              borderTop: `1px solid black`,
              backgroundColor: 'white',
            }}
            value={
              params.pathname.length > 25
                ? 'session'
                : params.pathname.replace('/', '') !== value
                ? 'home'
                : params.pathname.replace('/', '') === 'history'
                ? 'history'
                : value
            }
            onChange={handleChange}
          >
            <BottomNavigationAction
              label="Home"
              value="home"
              onClick={() => navigate('/home')}
              icon={<HomeOutlinedIcon />}
              sx={{
                color: 'black',
              }}
            />
            <BottomNavigationAction
              label="Session"
              value="session"
              onClick={(e) => {
                createRoom(e, currentUser).then((roomId) => {
                  navigate(`/${roomId}`);
                });
              }}
              icon={<ForumOutlinedIcon />}
              sx={{
                color: 'black',
              }}
            />
            <BottomNavigationAction
              label="History"
              value="history"
              onClick={() => navigate('/history')}
              icon={<FeedOutlinedIcon />}
              sx={{
                color: 'black',
              }}
            />
            <BottomNavigationAction
              label="Account"
              value="account"
              onClick={() => handleAccount()}
              icon={<AccountCircleOutlinedIcon />}
              sx={{
                color: 'black',
              }}
            />
          </BottomNavigation>
          <Routes>
            <Route
              path="/home"
              element={
                <Protect>
                  <Home handleSubscription={handleSubscription} />
                </Protect>
              }
            />
            <Route
              path="/:roomId"
              element={
                  <Session />

              }
            />
            <Route
              path="/history"
              element={
                <Protect>
                  <History handleSubscription={handleSubscription} />
                </Protect>
              }
            />
            <Route
              path="/account"
              element={
                <Protect>
                  <Account isSub={isSub} />
                </Protect>
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/reset-password/:userId"
              element={<ResetPassword />}
            />
            <Route path="/signup" element={<Register />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </>
      ) : (
        <LandingPage />
      )}
    </>
  );
}

export default MobileNavigation;
