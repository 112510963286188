import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkUser } from './redux/reducers/userReducer';
import ReactGA from 'react-ga';

import MobileNavigation from './components/Navigation/MobileNavigation';
import StandardNavigation from './components/Navigation/StandardNavigation';
import NewUserNavigation from './components/Navigation/NewUserNavigation';

import './App.css';

function App() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [currentUserView, setCurrentUserView] = useState(false);
  const [windowWidth, setWindowWidth] = useState(1000);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentUserView(currentUser);
  }, [currentUser]);

  useEffect(() => {
    dispatch(checkUser);
  }, [dispatch]);

  useLayoutEffect(() => {
    window.addEventListener('resize', () =>
      setWindowWidth(window.innerWidth)
    );
  }, [windowWidth]);

  // Google Analytics
  ReactGA.initialize('UA-189074005-1');

  if (currentUserView) {
    if (window.innerWidth < 768) {
      return <MobileNavigation />;
    } else {
      return <StandardNavigation />;
    }
  } else {
    return <NewUserNavigation />;
  }
}

export default App;
