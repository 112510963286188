import React from 'react';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LandingPage from '../../pages/LandingPage';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword';
import SignIn from '../../pages/SignIn';
import Register from '../../pages/Register';
import Session from '../../pages/Session';

import Koda_logo_black from '../../assets/koda_logo_black.png';

function NewUserNavigation() {
  const currentUser = useSelector((state) => state.user.currentUser);

  return (
    <>
      <nav className="sticky top-0 z-50 flex items-center justify-between p-2 lg:px-5 bg-white">
        <Link to={currentUser ? '/home' : '/'}>
          <img
            src={Koda_logo_black}
            width={60}
            layout="fixed"
            alt="logo"
          />
        </Link>

        <div className="flex items-center space-x-4 font-bold text-large">
          <Link to="/login">
            <button>Login</button>
          </Link>

          <Link to="/signup">
            <button className="inline-block px-4 py-2 text-black bg-button-primary hover:bg-button-primary-hover font-bold text-sm leading-tight rounded-full shadow-md  hover:shadow-lg focus:bg-button-primary-hover focus:shadow-lg focus:outline-none focus:ring-0 active:bg-emerald-800 active:shadow-lg transition duration-150 ease-in-out">
              Sign Up
            </button>
          </Link>
        </div>
      </nav>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:userId" element={<ResetPassword />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/:roomId" element={<Session />}/>
      </Routes>
    </>
  );
}

export default NewUserNavigation;
