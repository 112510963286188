import { useState, useLayoutEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	FormControl,
	Alert,
	OutlinedInput,
	InputAdornment,
} from '@mui/material';
import { IconButton } from '@material-ui/core';
import koda_logo_black from '../assets/koda_logo_black.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { handlePasswordUpdate, checkPassword } from '../utilities/user';

function ResetPassword() {
	const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isPasswordValid, setIsPasswordValid] = useState(false)
	const [showPasswordHelper, setShowPasswordHelper] = useState(false)
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const userId = window.location.pathname.replace('/reset-password', '');
	const resetPassword = handlePasswordUpdate(
		userId, 
        password, 
		setError,
        confirmPassword
	)

	// Password Eye Icon Start
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	// Password Eye Icon End

	const [windowWidth, setWindowWidth] = useState();

	useLayoutEffect(() => {
		window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
	}, [windowWidth]);

	return (
		<>
			<Helmet>
				<title>Imanyco Koda - Reset Password</title>
			</Helmet>
			<div className='flex justify-center m-4'>
				<div className='flex flex-col items-center justify-center p-6 rounded-lg shadow-lg border space-y-4 w-full sm:w-3/4'>
					<img src={koda_logo_black} width={250} layout='fixed' alt='Logo' />

					{/* Alert of reset password */}

					<div
						className='bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md'
						role='alert'
						style={{
							backgroundColor: 'aliceblue',
							borderTopWidth: '4px',
							borderTopColor: 'darkcyan',
							color: 'darkcyan',
							boxShadow:
								'0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
						}}>
						<div className='flex'>
							<div className='py-1'>
								<svg
									className='fill-current h-6 w-6 text-teal-500 mr-4'
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 20 20'>
									<path d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z' />
								</svg>
							</div>
							<div>
								{isPasswordUpdated ? (
									<p className='font-bold'>
										Your password successfully updated
									</p>
								) : password === '' ? (
									<p className='font-bold'>Please enter your password</p>
								) : confirmPassword === '' ? (
									<p className='font-bold'>
										Please enter your confirm password
									</p>
								) : password === confirmPassword ? (
									<p className='font-bold'>Passwords match successfully</p>
								) : (
									<p className='font-bold'>Your passwords do not match.</p>
								)}
								<p className='text-sm'>
									Make sure you know how these changes affect you.
								</p>
							</div>
						</div>
					</div>

					<h1 className='text-lg'>Forgot Password</h1>

					<div className='w-full items-center justify-center space-x-4 space-y-4'>
						<form
							className='flex flex-col w-full items-center space-y-4'
							onSubmit={(e) => {
								e.preventDefault();
								resetPassword();
								setTimeout(() => setIsPasswordUpdated(true), 5000);
								navigate('/login');
							  }}>
							<FormControl required className='w-full'>
								<div className='flex flex-col w-full space-y-4 justify-center items-center'>
									<div
										className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}>
										<FormControl
											className={` w-full form-control px-2 py-4 rounded-lg `}
											variant='outlined'>
											<OutlinedInput
												id='outlined-adornment-password'
												placeholder='Password'
												type={showPassword ? 'text' : 'password'}
												value={password}
												onChange={(e) => {
													setPassword(e.target.value);
													checkPassword(e.target.value, setIsPasswordValid);
												  }}
												  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{6,}"
												  title="Password must contain at least 6 characters including 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character."
												  onFocus={() => setShowPasswordHelper(true)}
												  onBlur={() => setShowPasswordHelper(false)}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															edge='end'>
															{showPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
									</div>
									{showPasswordHelper && (
                      <div className=" bg-white rounded-lg border border-gray-300 text-gray-700 px-3 py-2 text-xs">
                        <ul className="list-disc pl-4">
                          <li className={`text-${password.length >= 6 ? 'green' : 'red'}-500`}>
                            At least 6 characters long
                          </li>
                          <li className={`text-${/(?=.*[a-z])/.test(password) ? 'green' : 'red'}-500`}>
                            Contains a lowercase letter
                          </li>
                          <li className={`text-${/(?=.*[A-Z])/.test(password) ? 'green' : 'red'}-500`}>
                            Contains an uppercase letter
                          </li>
                          <li className={`text-${/(?=.*\d)/.test(password) ? 'green' : 'red'}-500`}>
                            Contains a number
                          </li>
                          <li className={`text-${/(?=.*\W)/.test(password) ? 'green' : 'red'}-500`}>
                            Contains a special character
                          </li>
                        </ul>
                      </div>
                    )}
									<div
										className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}>
										<FormControl
											className={` w-full form-control px-2 py-4 rounded-lg `}
											variant='outlined'>
											<OutlinedInput
												id='outlined-adornment-password'
												placeholder='Confirm Password'
												type={showConfirmPassword ? 'text' : 'password'}
												value={confirmPassword}
												onChange={(e) => setConfirmPassword(e.target.value)}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={handleClickShowConfirmPassword}
															onMouseDown={handleMouseDownPassword}
															edge='end'>
															{showConfirmPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
									</div>
								</div>
							</FormControl>
							{error && (
								<Alert
									className='text-red-500'
									variant='filled'
									severity='error'>
									{error}
								</Alert>
							)}
							<div
								className={`w-full md:w-96 flex flex-row border rounded-lg font-bold text-sm leading-tight shadow-sm `}>
								<button
									className={`w-full md:w-96 px-2 py-4 rounded-lg flex flex-row justify-center space-x-4 text-black bg-button-primary hover:bg-button-primary-hover font-bold text-sm leading-tight shadow-md hover:bg-emerald-700 hover:shadow-lg focus:bg-emerald-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-emerald-800 active:shadow-lg transition duration-150 ease-in-out`}
									type='submit'
									disabled={!isPasswordValid || password !== confirmPassword}>
									Reset
								</button>
							</div>
						</form>
					</div>
					<div className='flex flex-col justify-center text-center'>
						<Link to='/forgot-password' className='text-blue-600'>
							Forgot Password?
						</Link>
						<br></br>
						<h1>Don't have an account?</h1>

						<Link to='/signup' className='text-blue-600'>
							Sign Up
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default ResetPassword;
