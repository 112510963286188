import React, { useState } from "react";
// MUI Components
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
// stripe

// Util imports
import { makeStyles } from "@material-ui/core/styles";
// Custom Components
import CardInput from "./CardInput";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { Typography } from "@material-ui/core";
import Stripe from '../../utilities/stripe.js';

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: "35vh auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    margin: "2em auto 1em",
  },
});

function CheckoutForm({ isMonthly }) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const classes = useStyles();

  // State variables
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const subscriptionPlan = `${isMonthly ? "Monthly" : "Annual"}`;

  //stipe subscription functions
  const { handleSubmitSubMonthly, handleSubmitSubAnnual } = Stripe();
  const subscribeMonthly = handleSubmitSubMonthly(currentUser, email, setError, setSuccess);
  const subscribeAnnual = handleSubmitSubAnnual(currentUser, email, setError, setSuccess)

  return (
    <div className="flex flex-col items-start justify-start">
      <Card>
        <CardContent className={classes.content}>
          {/* label  */}
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Plan: {subscriptionPlan} Subscription
          </Typography>
          {/* price */}
          <Typography color="textSecondary" gutterBottom>
            Price: {subscriptionPlan === "Monthly" ? "$16.99" : "$152.91 (Save 24%)"}
          </Typography>
          {/* description */}

          <TextField
            label="Email"
            id="outlined-email-input"
            helperText={`Confirm email address for ${subscriptionPlan} subscription`}
            margin="normal"
            variant="outlined"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <CardInput />
          <div className={classes.div}>
            <Button
              className={classes.button}
              style={{
                padding: '1em 2em',
                border: 0,
                borderRadius: '30px',
                color: 'black',
                backgroundColor: '#D8F55A',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
              }}
              onClick={
                subscriptionPlan === "Monthly"
                  ? subscribeMonthly
                  : subscribeAnnual

              }
            >
              Pay Now
            </Button>
          </div>
          {success ? (
            <Alert
              className="text-green-500"
              variant="filled"
              severity="success"
            >
              {success}
            </Alert>
          ) : error ? (
            <Alert className="text-red-500" variant="filled" severity="error">
              {error}
            </Alert>
          ) : (
            " "
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default CheckoutForm;
