/* eslint-disable no-console */
import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { TextField } from '@mui/material';
import { sendForgotPasswordEmail } from '../utilities/user';
import koda_logo_black from '../assets/koda_logo_black.png';

function ForgotPassword() {
	const [email, setEmail] = useState('');
	const [showError, setShowError] = useState(false);
	const [messageFromServer, setMessageFromServer] = useState('');
	const [showNullError, setShowNullError] = useState(false);
	const [isReset, setIsReset] = useState(false);
	const navigate = useNavigate();
	const forgotPasswordEmail = sendForgotPasswordEmail(setIsReset, email, setEmail, navigate)
	  
	return (
		<>
			<Helmet>
        <title>Imanyco Koda - Forgot Password</title>
      </Helmet>
			<div className='flex justify-center m-4'>
				<div className='flex flex-col items-center justify-center p-6 rounded-lg shadow-lg border space-y-4 w-full sm:w-3/4'>
					<img src={koda_logo_black} width={250} layout='fixed' alt='Logo' />

					{/* Alert of reset password */}
					{isReset && (
						<div
							className='bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md'
							role='alert'
							style={{
								backgroundColor: 'aliceblue',
								borderTopWidth: '4px',
								borderTopColor: 'darkcyan',
								color: 'darkcyan',
								boxShadow:
									'0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
							}}>
							<div className='flex'>
								<div className='py-1'>
									<svg
										className='fill-current h-6 w-6 text-teal-500 mr-4'
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 20 20'>
										<path d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z' />
									</svg>
								</div>
								<div>
									<p className='font-bold'>
										Your reset password link was sent to your email.
									</p>
									<p className='text-sm'>
										Make sure you know how these changes affect you.
									</p>
								</div>
							</div>
						</div>
					)}

					<h1 className='text-lg'>Forgot Password?</h1>

					<div className='w-full items-center justify-center space-x-4 space-y-4'>
						<form
							className='profile-form flex flex-col w-full items-center space-y-4'
							onSubmit={forgotPasswordEmail}>
							<div className='flex flex-col w-full space-y-4 justify-center items-center'>
								<div
									className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}>
									<TextField
										required
										fullWidth
										className='form-control w-full px-2 py-4 rounded-lg'
										sx={{
											'& .MuiOutlinedInput-root': {
												'& > fieldset': {
													border: 0,
												},
											},
										}}
										placeholder='Email Address'
										id='email'
										type='email'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
							</div>

							<div
								className={`w-full md:w-96 flex flex-row border rounded-lg font-bold text-sm leading-tight shadow-sm `}>
								<button
									className={`w-full md:w-96 px-2 py-4 rounded-lg flex flex-row justify-center space-x-4 text-black bg-button-primary hover:bg-button-primary-hover font-bold text-sm leading-tight shadow-md hover:bg-emerald-700 hover:shadow-lg focus:bg-emerald-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-emerald-800 active:shadow-lg transition duration-150 ease-in-out`}
									type='submit'>
									Reset Password
								</button>
							</div>
						</form>
						{showNullError && (
							<div>
								<p>The email address cannot be empty.</p>
							</div>
						)}
						{showError && (
							<div>
								<p>
									That email address isn&apos;t recognized. Please try again or
									register for a new account.
								</p>
								<Button>Register</Button>
							</div>
						)}
						{messageFromServer === 'recovery email sent' && (
							<div>
								<h3>Password Reset Email Successfully Sent!</h3>
							</div>
						)}
					</div>
					<div className='flex flex-col justify-center text-center'>
						<br></br>
						<h1>Don't have an account?</h1>

						<Link to='/signup' className='text-blue-600'>
							Sign Up
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default ForgotPassword;
