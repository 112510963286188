// Action Types
const USER_LOGGED_IN = 'USER_LOGGED_IN';
const USER_ATTEMPT_LOGOUT = 'USER_ATTEMPT_LOGOUT';
const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
const USER_ATTEMPT_LOGIN = 'USER_ATTEMPT_LOGIN';
const USER_DELETED = 'USER_DELETED';
const SESSION_TIMES_DELETED = 'SESSION_TIMES_DELETED';

// Action Creators
export const loggedIn = (user) => {
  return {
    type: USER_LOGGED_IN,
    user
  };
};

export const logout = (dispatch, getState) => {
  dispatch({ type: USER_ATTEMPT_LOGOUT });
  fetch('/api/v1/users/logout')
    .then(() => {
      dispatch({ type: USER_LOGGED_OUT });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const checkUser = (dispatch, getState) => {
  dispatch({ type: USER_ATTEMPT_LOGIN });
  fetch('/api/v1/users/current')
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error('Failed to get current user');
      }
    })
    .then((data) => {
      dispatch(loggedIn(data));
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: USER_LOGGED_OUT });
    });
};

export const deleteSessionTimes = (userId) => {
  return (dispatch) => {
    fetch(`/api/v1/users/${userId}/delete-session-times`, {
      method: 'DELETE',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Failed to delete session times');
        }
      })
      .then((rowsDeleted) => {
        if (rowsDeleted === 1) {
          dispatch({ type: SESSION_TIMES_DELETED, userId });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const deleteUser = (userName, userId, reason, email) => {
  return (dispatch) => {
    fetch(`/api/v1/users/delete-user/${userName}/${userId}/${reason || 'no reason given'}/${email}`, {
      method: 'DELETE',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Failed to delete user');
        }
      })
      .then((rowsDeleted) => {
        if (rowsDeleted === 1) {
          dispatch({ type: USER_DELETED, userId, reason, email });
        } else {
          throw new Error('User not found');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// Reducer
const defaultState = {
  currentUser: null,
  loaded: false
};

export const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        ...state,
        currentUser: action.user,
        loaded: true,
      };
    case USER_LOGGED_OUT:
      return {
        ...state,
        currentUser: null,
        loaded: true,
      };
    case USER_DELETED:
      return {
        ...state,
        currentUser: state.currentUser && state.currentUser.id === action.userId
          ? null
          : state.currentUser,
      };
    case SESSION_TIMES_DELETED:
      // You can update the state here if needed
      return state;
    default:
      return state;
  }
};
