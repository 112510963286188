import { useSelector, useDispatch } from 'react-redux';
import { increaseFontSize, decreaseFontSize, chooseColorPalette } from '../../redux/reducers/accessibilityReducer';

import TextFieldsIcon from '@mui/icons-material/TextFields';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

export function FontSizeScale() {
    const currentFontSizeIndex = useSelector((state) => state.accessibility.currentFontSizeIndex);
    const fontSize = useSelector((state) => state.accessibility.fontSizes);
    const dispatch = useDispatch();

    const handleIncreaseSize = () => {
        dispatch(increaseFontSize());
    };

    const handleDecreaseSize = () => {
        dispatch(decreaseFontSize());
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
                <button
                    onClick={handleDecreaseSize}
                    disabled={currentFontSizeIndex === 0}
                    className={`${currentFontSizeIndex === 0 ? 'text-white' : 'text-black'} mr-2 py-1 px-2 bg-white`}
                    style={{
                        fontSize: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    -
                </button>
                <span style={{ fontSize: '40px', fontWeight: 'bold', margin: '0 8px' }}><TextFieldsIcon /></span>
                <button
                    onClick={handleIncreaseSize}
                    disabled={currentFontSizeIndex === fontSize.length - 1}
                    className={`${currentFontSizeIndex === 5 ? 'text-white' : 'text-black'} py-1 px-2 bg-white`}
                    style={{
                        fontSize: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    +
                </button>
            </div>
            <div style={{ marginTop: '8px', fontSize: '16px', textAlign: 'center', marginLeft: 30 }}>
                {fontSize[currentFontSizeIndex]}
            </div>
        </>
    )
}

export function CaptionBubbleColor() {
    const currentColorPalette = useSelector((state) => state.accessibility.currentColorPalette);
    const colorPalettes = useSelector((state) => state.accessibility.colorPalettes);
    const dispatch = useDispatch();
  
    const handleColorChoice = (paletteName) => {
      dispatch(chooseColorPalette(paletteName));
    };
  
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
          {Object.entries(colorPalettes).map(([paletteName, colors]) => (
            <div
              key={paletteName}
              style={{
                display: 'flex',
                transform: 'rotate(90deg)',
                marginRight: -30,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <button
                onClick={() => handleColorChoice(paletteName)}
                style={{
                  border: paletteName === currentColorPalette ? '2px solid black' : 'none',
                  borderRadius: '3px',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {colors.length > 0 ? (
                    colors.slice(0, 3).map((color, index) => (
                      <div key={index} style={{ backgroundColor: color, width: '20px', height: '20px' }} />
                    ))
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 60,
                        height: '20px',
                        border: '1px solid gray',
                        borderRadius: '3px',
                      }}
                    >
                      <ClearRoundedIcon />
                    </div>
                  )}
                </div>
              </button>
            </div>
          ))}
        </div>
      </>
    );
  }
