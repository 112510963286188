
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Stripe() {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

// Handle start stripe subscription (monthly)
const handleSubmitSubMonthly = (currentUser, email, setError, setSuccess) => async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    try {
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      });
  
      if (result.error) {
        setError(result.error.message);
        return;
      }
  
      const res = await axios.post(`/api/v1/stripe/monthly-sub-start`, {
        payment_method: result.paymentMethod.id,
        email: email,
      });
  
      const { client_secret, status, error } = res.data;
  
      if (error) {
        setError(error); // Set the error message from the backend response
        return;
      }
  
      if (status === "requires_action") {
        const confirmResult = await stripe.confirmCardPayment(client_secret);
        if (confirmResult.error) {
          setError(confirmResult.error.message);
          return;
        }
      }
  
      await axios.patch(`/api/v1/stripe/${currentUser.id}/subscribed-monthly`);
      setSuccess("Payment Successful!");
      setTimeout(() => {
    }, 4000);
      navigate("/home");
    } catch (error) {
      setError("Payment method was declined. Please try again."); // Set a generic error message
    }
  };
  
  
  

// Handle start stripe subscription (annual)
const handleSubmitSubAnnual = (currentUser, email, setError, setSuccess) => async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
        return;
    }

    try {
        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                email: email,
            },
        });

        if (result.error) {
            setError(result.error);
            return;
        }

        const res = await axios.post(`/api/v1/stripe/annual-sub-start`, {
            payment_method: result.paymentMethod.id,
            email: email,
        });

        const { client_secret, status } = res.data;

        if (status === "requires_action") {
            const confirmResult = await stripe.confirmCardPayment(client_secret);
            if (confirmResult.error) {
                setError(confirmResult.error);
                return;
            }
        }

        await axios.patch(`/api/v1/stripe/${currentUser.id}/subscribed-annual`);
        setSuccess("Payment Successful!");
        setTimeout(() => {
        }, 4000);
        navigate("/home");
    } catch (error) {
        setError("Payment method was declined. Please try again."); // Set a generic error message
    }
};

//change plan confirmation
const handleSubChange = (currentUser, setError, selectedPlan, setIsSubscriptionModal, setSuccess) => async (e) => {

    // Change to monthly plan
    const handlePlanChangeMonthly = async () => {
        try {
            await axios.post(`/api/v1/stripe/switch-to-monthly/${currentUser.id}`);
        } catch (error) {
            setError('Subscription change was unsuccessful')
        }
    };

    // Change to annual plan
    const handlePlanChangeAnnual = async () => {
        try {
            await axios.post(`/api/v1/stripe/switch-to-annual/${currentUser.id}`);
        } catch (error) {
            setError('Subscription change was unsuccessful')        }
    };

    if (selectedPlan === "annual") {
        try {
            await handlePlanChangeAnnual();
            setSuccess('Successfully changed to annual plan!');
            setTimeout(() => {
            }, 4000);
            setIsSubscriptionModal(false);
            window.location.reload()
        } catch (error) {
            setError('Subscription change was unsuccessful')        }
    } else if (selectedPlan === "monthly") {
        try {
            await handlePlanChangeMonthly();
            setSuccess('Successfully changed to monthly plan!');
            setTimeout(() => {
            }, 4000);
            setIsSubscriptionModal(false);
            window.location.reload()

        } catch (error) {
            setError('Subscription change was unsuccessful')        }
    } else {
        try {
            await handleSubCancel();
            setSuccess('Successfully changed to basic plan!');
            setTimeout(() => {
            }, 4000);
            setIsSubscriptionModal(false);
            window.location.reload()

        } catch (error) {
            setError('Subscription change was unsuccessful')        }
    }
};

// Cancel subscription
const handleSubCancel = (currentUser, setIsSubscriptionModal, setSuccess, setError) => async (e) => { 
    try {
        axios
            .post(`/api/v1/stripe/sub-delete`, {
                stripeId: currentUser.stripeId,
                subscriptionId: currentUser.subscriptionId,
            })
            .then(axios.patch(`/api/v1/stripe/${currentUser.id}/subscription-delete`))
            .then(
                setSuccess("Successfully cancelled subscription!"),
                setTimeout(() => {
                }, 4000),
                setIsSubscriptionModal(false),
                window.location.reload());
    } catch (error) {
        setError('Something went wrong. Please try again.')
    }
};

return {
    handleSubmitSubMonthly,
    handleSubmitSubAnnual,
    handleSubChange,
    handleSubCancel,
};
}