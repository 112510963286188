import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { Link } from 'react-router-dom';
import Zoom from '@mui/material/Zoom';

const HelpCenter = () => {
	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.black,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			// backgroundColor: theme.palette.common.black,
			color: 'rgba(0, 0, 0, 0.87)',
			// color: theme.palette.common.white,
			boxShadow: theme.shadows[1],
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(14),
			border: '1px solid #dadde9',
		},
	}));
	return (
		<>
			<HtmlTooltip
				TransitionComponent={Zoom}
				enterTouchDelay={0}
				title={
					<React.Fragment>
						<Typography color='inherit'>Help Center</Typography>
						<em>{'Need Help?'}</em> <b>{'Please write to us at'}</b>{' '}
						<u>
							<Link
								to='#'
								onClick={(e) => {
									window.location.href = 'mailto:koda@imanyco.com';
									e.preventDefault();
								}}>
								{'koda@imanyco.com'}
							</Link>
						</u>
						.
					</React.Fragment>
				}>
				<HelpIcon />
			</HtmlTooltip>
		</>
	);
};

export default HelpCenter;