import React from 'react';

function Feedback() {
	// Embedding the feedback form from google forms
	return (
		<div className='w-full  h-full'>
			<iframe
				src='https://docs.google.com/forms/d/e/1FAIpQLSf4hbwys-D8nhMQzHWvaafGaDplmK9Qlbts6bIXxK_s5osZhg/viewform?embedded=true'
				frameBorder='0'
				marginHeight='0'
				marginWidth='0'
				height='2000px'
				className='w-full self-center'
				title='feedback'>
				Loading…
			</iframe>
		</div>
	);
	// return <div>Feedback</div>;
}

export default Feedback;
