import React, { useState } from "react";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import { loggedIn } from '../../redux/reducers/userReducer'
import { Alert } from "@mui/material";
require('dotenv').config();


function GoogleRegisterButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [error, setError] = useState("");

  const onSuccess = (response) => {

    const userData = {
      firstName: response.profileObj.givenName,
      lastName: response.profileObj.familyName,
      email: response.profileObj.email,
      password: response.profileObj.googleId,
      googleId: response.profileObj.googleId,
      signUpMethod: "google",
      subscription: 'basic',
      avatar: response.profileObj.imageUrl

    }
    axios.post('/api/v1/users/google-auth', userData)
      .then(res => {
        dispatch(loggedIn(userData))
        navigate('/home')
      })
      .catch(res => {
        if (res) {
          setError("Email already exists")
        }
      })
  };

  return (
    <div>
      <div>
      {error && (
        <Alert
          className="text-red-500"
          variant="filled"
          severity="error"
          icon={false}
        >
          {error}
        </Alert>
      )}
</div>
<div>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_ID}
        buttonText="Sign up with Google"
        onSuccess={onSuccess}
      />
      </div>
    </div>
  );
};

export default GoogleRegisterButton;