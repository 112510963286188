import React, { useEffect } from 'react';
import { useState } from 'react';

function PreferenceSettings({}) {

	const[fontSize, setFontSize] = useState('base')

	return (
		<div className='grid grid-cols-2 gap-4 items-center justify-center space-y-4 mt-8'>
			<div className='col-span-2 flex flex-col space-y-4 items-start justify-center p-4'>
				<div className='flex flex-row font-bold space-x-4 justify-center items-center'>
					<h1>Caption Font-Size: </h1>
					<button
						className='border rounded-2xl px-4 text-base active:bg-gray-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50'
						onClick={() => setFontSize('base')}>
						aA
					</button>
					<button
						className='border rounded-2xl px-4 text-2xl active:bg-gray-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50'
						onClick={() => setFontSize('xl')}>
						aA
					</button>
					<button
						className='border rounded-2xl px-4 text-3xl active:bg-gray-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50'
						onClick={() => setFontSize('2xl')}>
						aA
					</button>
				</div>
			</div>
		</div>
	);
}

export default PreferenceSettings;
