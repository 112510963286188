import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	checkUser,
	logout,
	deleteUser,
	deleteSessionTimes,
} from '../../redux/reducers/userReducer';

// Notification for Session
import { Dialog, Transition } from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';

// Notifications
import { Fragment } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

function AccountManagement() {

	const currentUser = useSelector((state) => state.user.currentUser);
	const [userID, setUserID] = useState(currentUser.id);
	const [reason, setReason] = useState('');

	// Notifications
	const [show, setShow] = useState(false);

	// Account Deactivation Modal
	const [deactivateModal, setDeactivateModal] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(checkUser);
	}, [dispatch]);

	// Handle Logout
	const handleLogout = () => {
		dispatch(logout);
		navigate('/login');
	};

	const handleDeactivateAccount = () => {
		dispatch(deleteSessionTimes(userID));
		dispatch(deleteUser(currentUser.firstName, userID, reason, currentUser.email));
		setDeactivateModal(false);
		navigate('/')
	};

	return (
		<>
			<div className='flex flex-col space-y-4 w-full '>
				<div className='flex flex-col space-y-4 w-full p-4'>
					<div
						className={`w-full  flex flex-col leading-tight items-center text-center space-y-4 pt-12`}>
						<h1 className='text-2xl text-center font-bold'>Logout</h1>
						<button
							onClick={handleLogout}
							className='sm:w-96 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
							Logout
						</button>

						<h1 className='text-2xl text-center font-bold'>
							Deactivate Account
						</h1>
						<button
							onClick={() => setDeactivateModal(true)}
							className='sm:w-96 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
							Deactivate Account
						</button>
					</div>
				</div>
			</div>

			{/* Account Deactivation */}
			<Transition.Root show={deactivateModal} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={setDeactivateModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 overflow-y-auto'>
						<div className='flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
								<Dialog.Panel className='relative transform overflow-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
									<div className='flex sm:flex sm:items-start'>
										<div className='absolute top-0 right-0 pt-4 pr-4 sm:block'>
											<button
												type='button'
												className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
												onClick={() => {
													setDeactivateModal(false);
												}}>
												<span className='sr-only'>Close</span>
												<XMarkIcon className='h-6 w-6' aria-hidden='true' />
											</button>
										</div>
										<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full'>
											<Dialog.Title
												as='h3'
												className={`text-2xl font-bold leading-6 text-gray-900`}>
												Deactivate Account
											</Dialog.Title>
											<div className='mt-2 flex-col lg:flex-row p-4 space-y-2 text-left w-full'>
												<form
													className='flex flex-col w-full items-start justify-start space-y-4'
												>
													<div className='flex flex-col w-full space-y-4 justify-center items-start'>
														<div className='flex flex-col w-full space-y-4'>
															<p>
																The following actions will result in the loss of
																all your data and you will no longer be able to
																access your account.
															</p>
															<p>
																Please confirm your action by providing a reason
																for deactivating your account.
															</p>
															<label
																htmlFor='question'
																className='block mb-2 font-bold'>
																Reason:
															</label>
															<div
																className={`w-full lg:w-96 flex flex-col font-bold leading-tight shadow-sm  rounded-xl`}>
																<textarea
																	id='question'
																	className='block w-full border p-2 rounded-xl'
																	rows='6'
																	value={reason}
																	onChange={(e) =>
																		setReason(e.target.value || "no reason given")
																	}></textarea>
															</div>
														</div>
													</div>

													<div
														className={`w-full sm:w-96 flex flex-row space-x-4 font-bold justify-start leading-tight`}>
														<button
															onClick={() => handleDeactivateAccount()} // Need button to toggle back to original view and save/submit changes
															className={`w-full sm:w-96 px-2 py-4 rounded-lg flex flex-row justify-center space-x-4 bg-button-primary text-black font-bold leading-tight shadow-md hover:bg-button-primary-hover hover:shadow-lg focus:bg-button-primary-hover focus:shadow-lg focus:outline-none focus:ring-0 active:bg-button-primary-hover active:shadow-lg transition duration-150 ease-in-out`}
															type='submit'>
															<span>Submit</span>
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			{/* Global notification live region, render this permanently at the end of the document */}
			<div
				aria-live='assertive'
				className='pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6'>
				<div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition
						show={show}
						as={Fragment}
						enter='transform ease-out duration-300 transition'
						enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
						enterTo='translate-y-0 opacity-100 sm:translate-x-0'
						leave='transition ease-in duration-100'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='pointer-events-auto w-full max-w-sm overflow-auto rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
							<div className='p-4'>
								<div className='flex items-start'>
									<div className='flex-shrink-0'>
										<CheckCircleIcon
											className='h-6 w-6 text-green-400'
											aria-hidden='true'
										/>
									</div>
									<div className='ml-3 w-0 flex-1 pt-0.5'>
										<p className='text-sm font-medium text-gray-900'>
											Account Deactivation Request Submitted
										</p>
										<p className='mt-1 text-sm text-gray-500'>
											Your will receive an email from our Support Team shortly
											and your account will be deactivated.
										</p>
									</div>
									<div className='ml-4 flex flex-shrink-0'>
										<button
											type='button'
											className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
											onClick={() => {
												setShow(false);
											}}>
											<span className='sr-only'>Close</span>
											<XMarkIcon className='h-5 w-5' aria-hidden='true' />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</>
	);
}

export default AccountManagement;
