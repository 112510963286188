import React, { useEffect } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  KeyBindingUtil,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import axios from "axios";

import "../css/example.css";
import "../css/draft.css";
import "../css/rich-editor.css";
// Icons
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import HighlightIcon from "@mui/icons-material/Highlight";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import uuid from "react-uuid";

const { useState, useRef, useCallback } = React;
const NotesEditor = ({ roomId, isNotes, userId }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(true);
  const editor = useRef(null);
  const focus = () => {
    if (editor.current) editor.current.focus();
  };

  const handleKeyCommand = useCallback(
    (command, editorState) => {
      if (!editorState && command === "highlight") {
        editorState = RichUtils.toggleInlineStyle(
          this.state.editorState,
          "HIGHLIGHT"
        );
      }
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return "handled";
      }
      return "not-handled";
    },
    [setEditorState]
  );

  const mapKeyToEditorCommand = useCallback(
    (e) => {
      if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.key === "h") {
        return "highlight";
      }
      switch (e.keyCode) {
        case 9: // TAB
          const newEditorState = RichUtils.onTab(
            e,
            editorState,
            4 /* maxDepth */
          );
          if (newEditorState !== editorState) {
            setEditorState(newEditorState);
          }
          return;
        default: {
          return getDefaultKeyBinding(e);
        }
      }
    },
    [editorState, setEditorState]
  );

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = "RichEditor-editor";
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      className += " RichEditor-hidePlaceholder";
    }
  }

  // add/update user comments
  const updateComments = () => {
    let updatedComments = {
      comments: convertToRaw(contentState),
    };
    axios
      .patch(
        `/api/v1/conversations/${roomId}/update-comments/${userId}`,
        updatedComments
      )
      .then((res) => {
        if (res.status === 201) {
          setSuccess("Notes Saved!");
        } else {
          setError("Notes Not Saved!");
        }
        setOpen(true);
      });
  };

  // // sets conversation in editor
  useEffect(() => {
    if (roomId === "") {
      return;
    } else {
      axios
        .get(`/api/v1/conversations/${roomId}/get-comments/${userId}/`)
        .then((res) => {
          if (res.data.error === "no comments") {
            return;
          } else {
            setEditorState(
              EditorState.createWithContent(convertFromRaw(res.data[0].comment))
            );
          }
        });
    }
  }, [isNotes, roomId, userId]);

  return (
    <div
      className={` RichEditor-root flex flex-col w-full h-full p-2 overflow-x-auto justify-between`}
    >
      <div className="flex flex-col h-full">
        <div className={`flex flex-row border rounded`}>
          <InlineStyleControls
            editorState={editorState}
            onToggle={(inlineStyle) => {
              const newState = RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
              );
              setEditorState(newState);
            }}
          />
        </div>
        <div className={className} onClick={focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={setEditorState}
            placeholder="Write your comments here..."
            ref={editor}
            spellCheck={true} 
          />
        </div>
      </div>
      <button onClick={updateComments} className="border rounded p-2">
        Save
      </button>
      {<br />}
      {success ? (
        <Box sx={{ width: "100%" }}>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {success}
            </Alert>
          </Collapse>
        </Box>
      ) : error ? (
        <Box sx={{ width: "100%" }}>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {error}
            </Alert>
          </Collapse>
        </Box>
      ) : (
        " "
      )}
    </div>
  );
};

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
  HIGHLIGHT: {
    backgroundColor: "#faed27",
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

function StyleButton({ onToggle, active, label, style }) {
  let className = "RichEditor-styleButton";
  if (active) {
    className += " RichEditor-activeButton";
  }

  return (
    <span
      className={className}
      onMouseDown={(e) => {
        e.preventDefault();
        onToggle(style);
      }}
    >
      {label}
    </span>
  );
}

const INLINE_STYLES = [
  { label: <FormatBoldIcon />, style: "BOLD" },
  { label: <FormatItalicIcon />, style: "ITALIC" },
  { label: <FormatStrikethroughIcon />, style: "STRIKETHROUGH" },
  { label: <FormatUnderlinedIcon />, style: "UNDERLINE" },
  { label: <HighlightIcon />, style: "HIGHLIGHT" },
];

function InlineStyleControls({ editorState, onToggle }) {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={uuid()}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}

export default NotesEditor;
