const initialState = {
    selectedSession: null,
    sessionSelection: false,
    viewSessionMenu: true,
};

const SET_SELECTED_SESSION = 'SET_SELECTED_SESSION';
const SET_SESSION_SELECTION = 'SET_SESSION_SELECTION';
const SET_VIEW_SESSION_MENU = 'SET_VIEW_SESSION_MENU';

export const setSelectedSession = (session) => ({
    type: SET_SELECTED_SESSION,
    payload: session,
});

export const setSessionSelection = (selection) => ({
    type: SET_SESSION_SELECTION,
    payload: selection,
});

export const setViewSessionMenu = (menu) => ({
    type: SET_VIEW_SESSION_MENU,
    payload: menu,
});

export const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_SESSION:
            return {
                ...state,
                selectedSession: action.payload,
            };
        case SET_SESSION_SELECTION:
            return {
                ...state,
                sessionSelection: action.payload,
            };
        case SET_VIEW_SESSION_MENU:
            return {
                ...state,
                viewSessionMenu: action.payload,
            };
        default:
            return state;
    }
};
