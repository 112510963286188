import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { loggedIn } from '../redux/reducers/userReducer';
import { IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
	FormControl,
	Alert,
	OutlinedInput,
	InputAdornment,
	TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { handleLogin } from '../utilities/user';
import GoogleLoginButton from '../components/Account/GoogleLoginButton';


//logo
import koda_logo_black from '../assets/koda_logo_black.png';

// Mixpanel analytics
import Mixpanel from 'mixpanel-browser';
Mixpanel.init('50158065c2effca4d574cb213ffde886', {debug: true, ignore_dnt: true});


function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const userLogin = handleLogin(    
		email,
		password,
		setError,
		setEmail,
		setPassword,
		dispatch,
		navigate,
		loggedIn,
		)

	// Password Eye Icon Start
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const currentUser = useSelector((state) => state.user.currentUser);
	// const currentUserID = currentUser.id;
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	// Password Eye Icon End

	const handleClick = () => {
		console.log('Sign In button clicked');
		Mixpanel.track('User Signin Button Clicked');
	  }
	
	return (
		<>
			<Helmet>
        <title>Imanyco Koda - Log In</title>
      </Helmet>
			<div className='flex justify-center m-4'>
				<div className='flex flex-col items-center justify-center p-6 rounded-lg shadow-lg border space-y-4 w-full sm:w-3/4'>
					<img src={koda_logo_black} width={250} layout='fixed' alt='Logo' />

					<h1 className='text-lg'>Join the Conversation</h1>
					<div className='flex flex-row justify-center hover:bg-gray-200 m-2'>
						{/* <GoogleLoginButton /> */}
					</div>

					{/* <div className='flex flex-col items-center text-xs'>
						<h1>OR</h1>
					</div> */}
					<div className='w-full items-center justify-center space-x-4 space-y-4'>
						<form
							className='flex flex-col w-full items-center space-y-4'
							onSubmit={userLogin}>
							<FormControl required className='w-full'>
								<div className='flex flex-col w-full space-y-4 justify-center items-center'>
									<div
										className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}>
										<TextField
											required
											fullWidth
											className='w-full px-2 py-4 rounded-lg'
											sx={{
												'& .MuiOutlinedInput-root': {
													'& > fieldset': {
														border: 0,
													},
												},
											}}
											placeholder='Email'
											id='outlined-email-input'
											type='email'
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</div>

									<div
										className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}>
										<FormControl
											required
											className={` w-full form-control px-2 py-4 rounded-lg `}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& > fieldset': {
														border: 0,
													},
												},
											}}
											variant='outlined'>
											<OutlinedInput
												id='outlined-adornment-password'
												placeholder='Password'
												type={showPassword ? 'text' : 'password'}
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															edge='end'>
															{showPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
									</div>
								</div>
							</FormControl>
							{error && (
								<Alert
									className='text-red-500'
									variant='filled'
									severity='error'>
									{error}
								</Alert>
							)}
							<div
								className={`w-full md:w-96 flex flex-row border rounded-lg font-bold text-sm leading-tight shadow-sm `}>
								<button
									className={`w-full md:w-96 px-2 py-4 rounded-lg flex flex-row justify-center space-x-4 text-black bg-button-primary hover:bg-button-primary-hover font-bold text-sm leading-tight shadow-md hover:bg-emerald-700 hover:shadow-lg focus:bg-emerald-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-emerald-800 active:shadow-lg transition duration-150 ease-in-out`}
									type='submit'
									onClick={handleClick}>
									Login
								</button>
							</div>
						</form>
					</div>
					<div className='flex flex-col justify-center text-center'>
						<Link to='/forgot-password' className='text-blue-600'>
							Forgot Password?
						</Link>
						<br></br>
						<h1>Don't have an account?</h1>

						<Link to='/signup' className='text-blue-600'>
							Sign Up
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
