import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSpeakerNames, updateSpeakerNames } from '../redux/reducers/conversationReducer';

export const colorPalettes = {
  standard: ['#FFEB3B', '#FF80AB', '#FF9800', '#40C4FF', '#888DF2', '#0CC672', '#00000'],
  dark: ['#960031', '#0052A1', '#1C006B', '#62006B', '#AD005C', '#02594C', '#FFFFFF'],
  light: ['#FECD8C', '#A2D0FD', '#FFD1DF', '#938DFB', '#FBFDA2', '#5CC2B4', '#000000'],
  neutral: ['#FAFAFA', '#F5F5F5', '#E5E5E5', '#D4D4D4', '#A3A3A3', '#959595', '#000000'],
  none: [],
};

export function HistoryResults(bgColorEnabled, updatedConversation, uuid, currentUser, roomId) {
  const currentFontSizeIndex = useSelector((state) => state.accessibility.currentFontSizeIndex);
  const fontSize = useSelector((state) => state.accessibility.fontSizes);
  const currentFontSize = fontSize[currentFontSizeIndex];
  const currentColorPalette = useSelector((state) => state.accessibility.currentColorPalette);
  const colorPalettes = useSelector((state) => state.accessibility.colorPalettes);
  const currentCaptionColor = colorPalettes[currentColorPalette];
  const dispatch = useDispatch();
  const speakerNames = useSelector((state) => state.conversation.speakerNames);
  const [editingSpeaker, setEditingSpeaker] = useState(null);
  const [editName, setEditName] = useState('');
  const [hoveredSpeaker, setHoveredSpeaker] = useState(null);

  const getMessageTextColor = () => {
    if (!bgColorEnabled) return '';
    return currentCaptionColor[6];
  };

  const getMessageBgColor = (speaker) => {
    if (!bgColorEnabled || speaker < 1 || speaker > 6) return '';
    return currentCaptionColor[speaker - 1];
  };

  const updateSpeakerName = (speakerNumber, newName) => {
    const user = currentUser?.id;

    // Retrieve the original names set by the first user
    const originalNames = updatedConversation[0]?.userSpeakerNames || {};

    // Retrieve the existing names for the current user
    const existingNames = { ...(speakerNames[user] || {}) };

    // Create a new set of names for the current user
    const updatedUserSpeakerNames = { ...originalNames, ...existingNames, [speakerNumber]: newName };

    // Update the speaker names in the Redux store
    dispatch(
      setSpeakerNames({
        ...speakerNames,
        [user]: updatedUserSpeakerNames,
      })
    );

    // Update the userSpeakerNames in the last message of updatedConversation
    const lastMessageIndex = updatedConversation.length - 1;
    const lastMessage = updatedConversation[lastMessageIndex];

    // If the last message has userSpeakerNames, update it
    if (lastMessage && lastMessage.userSpeakerNames) {
      lastMessage.userSpeakerNames[user] = updatedUserSpeakerNames;
    }

    // Save the updated conversation to the backend
    const saveUrl = `/api/v1/conversations/${roomId}/save-history`;

    const updatedConversationData = {
      name: updatedConversation.name,
      conversation: updatedConversation,
      currentUser: currentUser,
    };

    // Make an HTTP PATCH request to save the updated conversation
    fetch(saveUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedConversationData),
    })
      .then((response) => {
        if (response.ok) {
          console.log('Conversation saved successfully!');
        } else {
          console.log('Failed to save conversation.');
        }
      })
      .catch((error) => {
        console.error('Error saving conversation:', error);
      });
  };

  useEffect(() => {
    dispatch(setSpeakerNames(updatedConversation[updatedConversation.length - 1]?.userSpeakerNames));
  }, [updatedConversation]);

  const handleEditClick = (speakerNumber) => {
    setEditingSpeaker(speakerNumber);
    setEditName(speakerNames?.[speakerNumber] || '');
  };

  if (!updatedConversation || updatedConversation.length === 0) {
    return (
      <div className=''>
        <p>There are no messages in this conversation!</p>
      </div>
    );
  } else {
    return (
      <>
        {updatedConversation[0].singleSpeaker === true ? (
          <div className='pb-10' style={{ fontSize: currentFontSize, lineHeight: '1.3em' }}>
            {updatedConversation[0].singleSpeaker === true ? (
              <div>
                <p>{updatedConversation.reduce((acc, curr) => acc + curr.text + ' ', '')}</p>
              </div>
            ) : null}
          </div>
        ) : (
          <div className='pb-10'>
            <ul className='space-y-1'>
              {updatedConversation.map((message, index) => {
                const trimmedMessage = message.text && message.text.trim();
                if (!trimmedMessage || /^[\.,;:'"!?-]+$/.test(trimmedMessage)) {
                  return null;
                }

                let userSpeakerNames = null;

                for (let i = updatedConversation.length - 1; i >= 0; i--) {
                  const msg = updatedConversation[i];
                  if (msg.userSpeakerNames) {
                    userSpeakerNames = msg.userSpeakerNames[currentUser?.id];
                    break;
                  }
                }

                const speakerName =
                  (userSpeakerNames && userSpeakerNames[message.speaker]) ||
                  (speakerNames &&
                    Object.keys(speakerNames).length > 0 &&
                    speakerNames[Object.keys(speakerNames)[0]][message.speaker]) ||
                  `Speaker ${message.speaker}`;

                const speakerId = index;
                const isEditing = editingSpeaker === speakerId;
                const isHovered = hoveredSpeaker === speakerId;

                return (
                  <li key={uuid()} className='flex justify-start p-1 pt-6' >
                    <div>
                      <p>
                        {isEditing ? (
                          <>
                            <input
                              value={editName}
                              placeholder='enter name here...'
                              onChange={(e) => setEditName(e.target.value)}
                              autoFocus
                            />
                            <button
                              onClick={() => {
                                updateSpeakerName(message.speaker, editName);
                                setEditingSpeaker(null);
                              }}
                              style={{
                                backgroundColor: 'green',
                                color: 'white',
                                borderRadius: '50%',
                                width: '30px',
                                height: '30px',
                                textAlign: 'center',
                                lineHeight: '1.3em',
                                marginLeft: '10px',
                              }}
                            >
                              ✓
                            </button>
                            <button
                              onClick={() => {
                                setEditName(speakerNames?.[currentUser?.id]?.[message.speaker] || `Speaker ${message.speaker}`);
                                setEditingSpeaker(null);
                              }}
                              style={{
                                backgroundColor: 'red',
                                color: 'white',
                                borderRadius: '50%',
                                width: '30px',
                                height: '30px',
                                textAlign: 'center',
                                lineHeight: '1.3em',
                                marginLeft: '10px',
                              }}
                            >
                              ✕
                            </button>
                          </>
                        ) : (
                          <span
                            className={`font-bold px-4 ${isHovered ? 'cursor-pointer' : ''}`}
                            style={{ fontSize: currentFontSize }}
                            onMouseEnter={() => setHoveredSpeaker(speakerId)}
                            onMouseLeave={() => setHoveredSpeaker(null)}
                            onClick={() => handleEditClick(speakerId)}
                          >
                            {isHovered && <span style={{ color: 'black' }}>&#x270E;</span>}
                            {speakerName}:
                          </span>
                        )}
                      </p>

                      <p
                        className={`block p-4 px-6 rounded-3xl border shadow-lg`}
                        style={{
                          wordWrap: 'break-word',
                          whiteSpace: 'normal',
                          fontSize: currentFontSize,
                          padding: currentFontSize === '16px' ? null : currentFontSize,
                          marginTop: currentFontSize === '16px' ? '8px' : currentFontSize,
                          marginBottom: currentFontSize === '16px' ? '-16px' : currentFontSize,
                          lineHeight: '1.3em',
                          backgroundColor: getMessageBgColor(message.speaker),
                          color: getMessageTextColor(),
                        }}
                      >
                        {message.text}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    );
  }
}

export const premNotification = (premUser, Link, handleSubscription) => {
  if (!premUser) {
    return (
      <div className='non-premium-notification rounded-2xl mx-auto bg-gray-500 mt-4'>
        <div className='w-full h-full flex flex-col sm:flex-row space-x-4 justify-center items-center px-8 py-8 text-black font-bold space-y-4 text-center sm:text-left'>
          <div className='flex flex-col space-y-4 items-center'>
            <p className='text-xl'>
              To view all of your previous
              <br />
              sessions, please upgrade!
            </p>
            <Link to='/account'>
              <button
                className={`rounded-2xl space-x-4 px-4 py-2 border font-bold leading-tight shadow-sm text-white bg-black hover:bg-gray-700 hover:shadow-lg focus:bg-gray-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}
                onClick={handleSubscription}
              >
                Upgrade Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
