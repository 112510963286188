import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

function Protect(props) {
  
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

    if (!currentUser) {
      navigate("/login");
      return null;
    } 
  return <>{props.children}</>;
}

export default Protect;
