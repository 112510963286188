import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Notifications
import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { handleProfileUpdate, handleEmailUpdate, handlePasswordUpdate, checkPassword } from '../../utilities/user.js';

function AccountSettings() {
	const currentUser = useSelector((state) => state.user.currentUser);
	const [firstName, setFirstName] = useState(
		currentUser.firstName || 'First Name'
	);
	const [lastName, setLastName] = useState(currentUser.lastName || 'Last Name');
	const [phoneNumber, setPhoneNumber] = useState(
		currentUser.phoneNumber || 'Phone Number'
	);
	const [email, setEmail] = useState(currentUser.email || 'Email');
	const [password, setPassword] = useState('Password');
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isPasswordValid, setIsPasswordValid] = useState(false)
	const [showPasswordHelper, setShowPasswordHelper] = useState(false)
	const [googleUser, setGoogleUser] = useState(
		currentUser.signUpMethod === 'google'
	);
	const googleUserView = googleUser ? 'hidden' : 'visible';

	// Notifications
	const [show, setShow] = useState(false);
	const [error, setError] = useState('');

	//profile update functions
	const updateProfile = handleProfileUpdate(currentUser, firstName, lastName, phoneNumber, setShow, setError);
	const updateEmail = handleEmailUpdate(currentUser, email, setShow, setError)
	const updatePassword = handlePasswordUpdate(currentUser.id, password, setError, confirmPassword)

	// conditional views
	const [changeAccountSettings, setChangeAccountSettings] = useState(false);
	const [changeEmailSettings, setChangeEmailSettings] = useState(false);
	const [changePasswordSettings, setChangePasswordSettings] = useState(false);
	const changeSettingsUserInput = changeAccountSettings ? false : true;
	//View for Account Settings (Button View, Cancel Button View and Editable Fields View)
	const changeAccountSettingsView = changeAccountSettings
		? 'visible'
		: 'hidden';
	const changeAccountSettingsButtonView = !changeAccountSettings
		? 'visible'
		: 'hidden';
	const cancelAccountSettingsButtonView = changeAccountSettings
		? 'visible'
		: 'hidden';
	//View for Email Settings (Button View, Cancel Button View and Editable Fields View)
	const changeEmailSettingsView = changeEmailSettings ? 'visible' : 'hidden';
	const changeEmailSettingsButtonView = !changeEmailSettings
		? 'visible'
		: 'hidden';
	const cancelEmailSettingsButtonView = changeEmailSettings
		? 'visible'
		: 'hidden';
	//View for Password Settings (Button View, Cancel Button View and Editable Fields View)
	const changePasswordSettingsView = changePasswordSettings
		? 'visible'
		: 'hidden';
	const changePasswordSettingsButtonView = !changePasswordSettings
		? 'visible'
		: 'hidden';
	const cancelPasswordSettingsButtonView = changePasswordSettings
		? 'visible'
		: 'hidden';
	const changeEmailSettingsUserInput = changeEmailSettings ? false : true;
	const changePasswordSettingsUserInput = changePasswordSettings ? false : true;

	return (
		<div className={`flex flex-row w-full`}>
			<div className={`flex flex-col space-y-4 w-full`}>
				{/* display dark/contrast mode */}
				{/* Account Settings View */}
				<div className='flex flex-col space-y-4 w-full'>
					<div className='flex flex-row justify-center items-center w-full'>
						<h1 className='text-2xl p-2'>Contact Settings</h1>

						<div
							className={`${changeAccountSettingsButtonView} flex flex-row border rounded-lg font-bold  leading-tight shadow-sm `}>
							<button
								onClick={() => setChangeAccountSettings(true)}
								className={`w-7 h-7 rounded-lg flex flex-row justify-center items-center border font-bold  leading-tight shadow-sm hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-5 w-5'
									viewBox='0 0 20 20'
									fill='currentColor'>
									<path
										fillRule='evenodd'
										d='M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z'
										clipRule='evenodd'
									/>
								</svg>
							</button>
						</div>

						<div
							className={`${cancelAccountSettingsButtonView} flex flex-row border rounded-lg font-bold  leading-tight shadow-sm `}>
							<button
								onClick={() => setChangeAccountSettings(false)}
								className={`w-7 h-7 rounded-lg flex flex-row justify-center items-center border font-bold  leading-tight shadow-sm hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}>
								<XMarkIcon className='h-5 w-5' />
							</button>
						</div>
					</div>
					<div
						className={` w-full items-center justify-center space-x-4 space-y-4`}>
						<form
							className='flex flex-col w-full items-center justify-center space-y-4'
							onSubmit={updateProfile}>
							<div className='flex flex-col w-full space-y-4 justify-center items-center'>
								<div className='flex flex-col w-full  space-y-4 '>
									<div className='flex flex-col space-y-4 px-4 items-center w-full'>
										<div className='flex flex-col w-full items-center lg:w-96 '>
											<h1 className='text-lg self-start'>First Name:</h1>
											<div
												className={`w-full flex flex-col border rounded-lg font-bold  leading-tight shadow-sm `}>
												<input
													className='form-control w-full px-2 py-4 rounded-lg'
													type='text'
													placeholder='First Name'
													value={firstName}
													onChange={(e) => setFirstName(e.target.value)}
													disabled={changeSettingsUserInput}
												/>
											</div>
										</div>
										<div className='flex flex-col w-full items-center lg:w-96 '>
											<h1 className='text-lg self-start'>Last Name:</h1>
											<div
												className={`w-full lg:w-96 flex flex-col border rounded-lg font-bold  leading-tight shadow-sm `}>
												<input
													type='text'
													className={`form-control w-full px-2 py-4 rounded-lg `}
													placeholder='Last Name'
													value={lastName}
													onChange={(e) => setLastName(e.target.value)}
													disabled={changeSettingsUserInput}
												/>
											</div>
										</div>
										<div className='flex flex-col w-full items-center lg:w-96 '>
											<h1 className='text-lg self-start'>Phone Number:</h1>
											<div
												className={`w-full lg:w-96 flex flex-col border rounded-lg font-bold  leading-tight shadow-sm `}>
												<input
													type='text'
													className={`form-control w-full px-2 py-4 rounded-lg`}
													placeholder='Phone Number'
													value={phoneNumber}
													onChange={(e) => setPhoneNumber(e.target.value)}
													disabled={changeSettingsUserInput}
												/>
											</div>
										</div>

										<div
											className={` ${changeAccountSettingsView}  w-full  md:w-96 flex justify-start  border rounded-lg font-bold  leading-tight shadow-sm `}>
											<button
												onClick={() => setChangeAccountSettings(false)} // Need button to toggle back to original view and save/submit changes
												className={` w-full md:w-96 px-2 py-4 rounded-lg flex flex-row justify-center space-x-4 bg-green-500 text-white font-bold leading-tight shadow-md hover:bg-emerald-700 hover:shadow-lg focus:bg-emerald-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-emerald-800 active:shadow-lg transition duration-150 ease-in-out`}
												type='submit'>
												<span>Save Settings</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				{/* Email Settings  */}
				<div className={`${googleUserView} w-full justify-center items-center`}>
					<div className='flex flex-row justify-center items-center w-full'>
						<h1 className='text-2xl p-2'>Email Settings</h1>

						<div
							className={`${changeEmailSettingsButtonView} flex flex-row border rounded-lg font-bold  leading-tight shadow-sm `}>
							<button
								onClick={() => setChangeEmailSettings(true)}
								className={`w-7 h-7 rounded-lg flex flex-row justify-center items-center border font-bold  leading-tight shadow-sm hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-5 w-5'
									viewBox='0 0 20 20'
									fill='currentColor'>
									<path
										fillRule='evenodd'
										d='M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z'
										clipRule='evenodd'
									/>
								</svg>
							</button>
						</div>
						<div
							className={`${cancelEmailSettingsButtonView} flex flex-row border rounded-lg font-bold  leading-tight shadow-sm `}>
							<button
								onClick={() => setChangeEmailSettings(false)}
								className={`w-7 h-7 rounded-lg flex flex-row justify-center items-center border font-bold  leading-tight shadow-sm hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}>
								<XMarkIcon className='h-5 w-5' />
							</button>
						</div>
					</div>

					<div
						className={`w-full items-center justify-center space-x-4 space-y-4 px-4`}>
						<form
							className='flex flex-col w-full items-center justify-center space-y-4'
							onSubmit={updateEmail}>
							<div className='flex flex-col space-y-4 px-2 items-center w-full'>
								<div className='flex flex-col w-full items-center lg:w-96 '>
									<h1 className='text-lg self-start'>Email:</h1>
									<div
										className={`w-full lg:w-96 flex flex-col border rounded-lg font-bold  leading-tight shadow-sm `}>
										<input
											type='email'
											className={`form-control w-full px-2 py-4 rounded-lg `}
											placeholder='Email'
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											disabled={changeEmailSettingsUserInput}
										/>
									</div>
								</div>
							</div>

							<div
								className={`${changeEmailSettingsView} w-full md:w-96 flex flex-row border rounded-lg font-bold justify-start leading-tight shadow-sm `}>
								<button
									onClick={() => setChangeEmailSettings(false)} // Need button to toggle back to original view and save/submit changes
									className={` w-full md:w-96 px-2 py-4 rounded-lg flex flex-row justify-center space-x-4 bg-green-500 text-white font-bold leading-tight shadow-md hover:bg-emerald-700 hover:shadow-lg focus:bg-emerald-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-emerald-800 active:shadow-lg transition duration-150 ease-in-out`}
									type='submit'>
									<span>Save Settings</span>
								</button>
							</div>
						</form>
					</div>
				</div>
				{/* Password View */}
				<div className={`${googleUserView} w-full `}>
					<div className='flex flex-row justify-center items-center'>
						<h1 className='text-2xl p-2'>Password Settings</h1>
						<div
							className={`${changePasswordSettingsButtonView} flex flex-row border rounded-lg font-bold  leading-tight shadow-sm `}>
							<button
								onClick={() => setChangePasswordSettings(true)}
								className={`w-7 h-7 rounded-lg flex flex-row justify-center items-center border font-bold  leading-tight shadow-sm hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-5 w-5'
									viewBox='0 0 20 20'
									fill='currentColor'>
									<path
										fillRule='evenodd'
										d='M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z'
										clipRule='evenodd'
									/>
								</svg>
							</button>
						</div>
						<div
							className={`${cancelPasswordSettingsButtonView} flex flex-row border rounded-lg font-bold  leading-tight shadow-sm `}>
							<button
								onClick={() => setChangePasswordSettings(false)}
								className={`w-7 h-7 rounded-lg flex flex-row justify-center items-center border font-bold  leading-tight shadow-sm hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out`}>
								<XMarkIcon className='h-5 w-5' />
							</button>
						</div>
					</div>

					<div
						className={` w-full items-center justify-center space-x-4 space-y-4 px-4`}>
						<form
							className='flex flex-col w-full items-center justify-center space-y-4'
							onSubmit={(e) => {
								e.preventDefault();
								updatePassword();
								password === confirmPassword ? (
								setShow(true)
								):(
									setShow(false)
								);
							  }}>
							<div className='flex flex-col space-y-4 px-2 items-center w-full'>
								<div className='flex flex-col w-full items-center lg:w-96 '>
									<h1 className='text-lg self-start'>Password:</h1>
									<div
										className={`w-full lg:w-96 flex flex-col border rounded-lg font-bold  leading-tight shadow-sm `}>
										<input
											type='password'
											className={`form-control w-full px-2 py-4 rounded-lg`}
											placeholder='Password'
											value={password}
											onChange={(e) => {
												setPassword(e.target.value);
												checkPassword(e.target.value, setIsPasswordValid);
											  }}
											  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{6,}"
											  title="Password must contain at least 6 characters including 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character."
											  onFocus={() => setShowPasswordHelper(true)}
											  onBlur={() => setShowPasswordHelper(false)}
											disabled={changePasswordSettingsUserInput}
										/>
										                    {showPasswordHelper && (
                      <div className=" bg-white rounded-lg border border-gray-300 text-gray-700 px-3 py-2 text-xs">
                        <ul className="list-disc pl-4">
                          <li className={`text-${password.length >= 6 ? 'green' : 'red'}-500`}>
                            At least 6 characters long
                          </li>
                          <li className={`text-${/(?=.*[a-z])/.test(password) ? 'green' : 'red'}-500`}>
                            Contains a lowercase letter
                          </li>
                          <li className={`text-${/(?=.*[A-Z])/.test(password) ? 'green' : 'red'}-500`}>
                            Contains an uppercase letter
                          </li>
                          <li className={`text-${/(?=.*\d)/.test(password) ? 'green' : 'red'}-500`}>
                            Contains a number
                          </li>
                          <li className={`text-${/(?=.*\W)/.test(password) ? 'green' : 'red'}-500`}>
                            Contains a special character
                          </li>
                        </ul>
                      </div>
                    )}
									</div>
								</div>
							</div>

							<div className='flex flex-col space-y-4 px-2 items-center w-full'>
								<div className='flex flex-col w-full items-center lg:w-96 '>
									<h1 className='text-lg self-start'>Confirm Password:</h1>
									<div
										className={`w-full lg:w-96 flex flex-col border rounded-lg font-bold  leading-tight shadow-sm `}>
										<input
											type='password'
											className={`form-control w-full px-2 py-4 rounded-lg`}
											placeholder='Confirm Password'
											value={confirmPassword}
											onChange={(e) => setConfirmPassword(e.target.value)}
											disabled={changePasswordSettingsUserInput}
										/>
									</div>
								</div>
							</div>
							{password === confirmPassword ? "" : error}
							<div
								className={`${changePasswordSettingsView} w-full md:w-96 flex flex-row border rounded-lg font-bold  leading-tight shadow-sm `}>
								<button
									onClick={() => setChangePasswordSettings(false)} // Need button to toggle back to original view and save/submit changes
									className={` w-full md:w-96 px-2 py-4 rounded-lg flex flex-row justify-center space-x-4  bg-green-500 text-white font-bold  leading-tight shadow-md hover:bg-emerald-700 hover:shadow-lg focus:bg-emerald-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-emerald-800 active:shadow-lg transition duration-150 ease-in-out`}
									type='submit'
									disabled={!isPasswordValid}
									>
										
									<span>Save Settings</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			{/* Global notification live region, render this permanently at the end of the document */}
			<div
				aria-live='assertive'
				className='pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6'>
				<div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition
						show={show}
						as={Fragment}
						enter='transform ease-out duration-300 transition'
						enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
						enterTo='translate-y-0 opacity-100 sm:translate-x-0'
						leave='transition ease-in duration-100'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
							<div className='p-4'>
								<div className='flex items-start'>
									<div className='flex-shrink-0'>
										<CheckCircleIcon
											className='h-6 w-6 text-green-400'
											aria-hidden='true'
										/>
									</div>
									<div className='ml-3 w-0 flex-1 pt-0.5'>
										<p className='text-sm font-medium text-gray-900'>
											Successfully Saved!
										</p>
										<p className='mt-1 text-sm text-gray-500'>
											Your profile has been updated.
										</p>
									</div>
									<div className='ml-4 flex flex-shrink-0'>
										<button
											type='button'
											className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
											onClick={() => {
												setShow(false);
											}}>
											<span className='sr-only'>Close</span>
											<XMarkIcon className='h-5 w-5' aria-hidden='true' />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</div>
	);
}

export default AccountSettings;
