import React, { useState, useLayoutEffect } from 'react';
import DashboardContainer from '../components/Containers/DashboardContainer.js';
import ReactGA from 'react-ga';

// import React from "react";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { checkUser } from '../redux/reducers/userReducer';
import axios from 'axios';

// import NoProfileImg from "../assets/NoImg.png";

function Home({
	display,
	handleSubscription,
}) {
	const currentUser = useSelector((state) => state.user.currentUser);
	const dispatch = useDispatch();

	//checks if user has active subscription
	useEffect(() => {
		if (!currentUser.subscriptionId) {
		  return;
		} else {
		  axios
			.get(`/api/v1/stripe/check-subscription/${currentUser.subscriptionId}`)
			.then((res) => {
			  const subStatus = res.data.status
			  if (subStatus === 'active') {
				return;
			  } else {
				axios
				  .patch(`/api/v1/stripe/${currentUser.id}/subscription-delete`)
				  .then(console.log('subscription deleted'));
			  }
			})
			.catch((err) => console.log(err));
		}
	  }, [
		currentUser.id,
		currentUser.stripeId,
		currentUser.subscriptionId,
	  ]);


	//google analytics
	ReactGA.initialize('UA-189074005-1');
	ReactGA.pageview('/home');

	useEffect(() => {
		dispatch(checkUser);
	}, [dispatch]);

	const [windowWidth, setWindowWidth] = useState();

	useLayoutEffect(() => {
		window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
	}, [windowWidth]);

	return (
		<>
			<Helmet>
				<title>Imanyco Koda - Home</title>
			</Helmet>
			<div
				className={`${display} w-full md:pl-24 flex flex-col space-y-2 h-full`}>
				<DashboardContainer
					currentUser={currentUser}
					handleSubscription={handleSubscription}
				/>
			</div>
		</>
	);
}

export default Home;
