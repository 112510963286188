import React, { useEffect, useState } from 'react';
import CheckoutForm from './CheckoutForm';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert } from "@mui/material";
import { Button } from 'antd';
// Notification for Session
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, } from '@heroicons/react/24/outline';
// Notifications
import { Fragment } from 'react';
import '../css/manageSubscriptionModal.scss';
import Stripe from '../../utilities/stripe.js';

function SubscriptionSettings() {

	const location = useLocation();
	const [isMonthly, setIsMonthly] = useState(false);
	const [isAnnual, setIsAnnual] = useState(true);
	const [displayBuyModal, setDisplayBuyModal] = useState(false);
	const currentUser = useSelector((state) => state.user.currentUser);
	const [planType, setPlanType] = useState(currentUser?.subscription || 'basic');
	const displayPlan = location.pathname === '/' ? null : (planType == null ? 'Basic Plan' : 'Pro Plan');
	const displayPlanOptions = planType == null ? 'flex' : 'flex';
	const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
	const [success, setSuccess] = useState('');
	const [error, setError] = useState('');
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [cancelConfirm, setCancelConfirm] = useState(false)
	const isMonthlyActive = isMonthly
		? 'text-gray-900 bg-white border-gray-200'
		: 'text-gray-700 border-transparent';
	const isYearlyActive = !isMonthly
		? 'text-gray-900 bg-white border-gray-200'
		: 'text-gray-700 border-transparent';
	const { handleSubChange, handleSubCancel } = Stripe();
	const changePlan = handleSubChange(currentUser, setError, selectedPlan, setIsSubscriptionModal, setSuccess);
	const cancelPlan = handleSubCancel(currentUser, setIsSubscriptionModal, setSuccess, setError)

	const basicTiers = [
		{
			name: 'Basic',
			href: '#',
			price: 0,
			description: "Access Koda's basic features",
			includedFeatures: [
				'30 minute session limit',
				'Unlimited single speaker session time',
				'15 minutes of diarization per month',
				'Up to 2 people per session',
				'Max diarization (3 speakers)',
				'View your last 2 sessions',
			],
		},
	];

	const monthlyTiers = [
		{
			name: 'Pro',
			href: '#',
			priceMonthly: 16.99,
			description: 'Enjoy the full functionality of Koda',
			includedFeatures: [
				'120 minute session limit',
				'Unlimited single speaker session time',
				'1000 minutes of diarization per month',
				'Up to 5 people per session',
				'Max diarization (5 speakers)',
				'View your last 20 sessions',
			],
		},
	];

	const annualTiers = [
		{
			name: 'Pro (Save 24%)',
			href: '#',
			priceYearly: 155,
			description: 'Enjoy the full functionality of Koda',
			includedFeatures: [
				'120 minute session limit',
				'Unlimited single speaker session time',
				'1000 minutes of diarization per month',
				'Up to 5 people per session',
				'Max diarization (5 speakers)',
				'View your last 20 sessions',
			],
		},
	];

	const enterpriseTiers = [
		{
			name: 'Enterprise',
			href: '#',
			price: 'Contact Sales',
			description:
				'A customized plan for organizations that need additional features, control, and support.',
			includedFeatures: [

			],
		},
	];

	const tiers = isMonthly ? monthlyTiers : annualTiers;

	const handleMonthly = () => {
		setIsMonthly(true);
		setIsAnnual(false);
	};

	const handleAnnual = () => {
		setIsMonthly(false);
		setIsAnnual(true);
	};

	const handlePlanChange = (e) => {
		setSelectedPlan(e.target.value);
	};

	const handleCancelConfirm = () => {
		setCancelConfirm(true);
	};

	//checks which plan the user has
	useEffect(() => {
		if (currentUser && currentUser.subscription === 'monthly') {
			setPlanType('Monthly $16.99/mo');
			setIsMonthly(true);
			setIsAnnual(false);
		} else if (currentUser && currentUser.subscription === 'annual') {
			setPlanType('Annual $155/yr');
			setIsMonthly(false);
			setIsAnnual(true);
		} else {
			setPlanType(null);
		}
	}, [currentUser]);


	return (
		<div className='flex justify-center items-center w-full h-full'>
			<div className='mx-auto max-w-7xl  px-4 sm:px-6 lg:px-8 items-center justify-center'>
				{location.pathname !== '/' && (
					<div className={`${location.pathname === '/home' ? 'hidden' :'mt-5 w-full md:w-96'}`}>
						<div className='overflow-auto rounded-lg bg-white px-4 py-5 sm:p-6'>
							<button
								onClick={() => setIsSubscriptionModal(true)}
								type='button'
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: 'black',
									color: 'white',
								}}
								className='relative ml-0.5 w-1/2 whitespace-nowrap rounded-md border py-2.5 text-sm font-medium focus:z-10 focus:outline-none sm:w-auto sm:px-8'>
								{window.innerWidth < 370 ? 'Manage Plan' : 'Manage Subscription'}
							</button>
						</div>
					</div>
				)}

				<div className={`${displayPlanOptions} align-center items-center mt-4`}>
					<div style={{ width: '100%' }}>
						<div
							className='rounded-lg'
							style={
								displayPlan === 'Basic Plan'
									? {
										float: 'left',
										boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.6)',
										marginRight: '20px',
										marginBottom: '20px',
									}
									: { float: 'left', marginRight: '20px', marginBottom: '20px' }
							}>
							{basicTiers.map((tier) => (
								<>
									<div
										key={tier.name}
										className={`${location.pathname === '/home' && displayPlan !== 'Basic Plan' ? 'hidden' : 'divide-y divide-gray-200 self-center rounded-lg border border-gray-300 shadow-sm w-full'}`}
										style={
											displayPlan === 'Basic Plan'
												? { position: 'relative', backgroundColor: '#EAEAEA' }
												: null
										}>
										{displayPlan === 'Basic Plan' && (
											<div
												style={{
													position: 'absolute',
													top: '-18px',
													right: '-18px',
												}}>
												<CheckCircleIcon
													style={{ fontSize: 'xxx-large' }}
													className='w-5 text-green-500'
													aria-hidden='true'
												/>
											</div>
										)}
										<div className='p-6'>
											<div className='sm:align-center sm:flex sm:flex-col pb-4'>
												<div
													className='relative flex self-center rounded-lg bg-green-100 p-0.5'
													style={{ visibility: 'hidden' }}>
													<h6
														type='heading'
														className={`relative ml-0.5 w-full whitespace-nowrap rounded-md py-2.5 text-sm font-medium focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:w-auto sm:px-8`}
														style={{
															textAlign: 'center',
															visibility: 'hidden',
														}}>
														Basic Plan
													</h6>
												</div>
											</div>
											<h2 className='text-lg font-medium leading-6 text-gray-900'>
												{tier.name}
											</h2>
											<p className='mt-4 text-sm text-gray-500'>
												{tier.description}
											</p>
											<p className='mt-8'>
												<span className='text-3xl font-bold tracking-tight text-gray-900'>
													${tier.price}
												</span>{' '}
												<span className='text-base font-medium text-gray-500'>
													/mo
												</span>
											</p>
										</div>
										<div className='px-6 pt-6 pb-8'>
											<h3 className='text-sm font-medium text-gray-900'>
												What's included
											</h3>
											<ul className='mt-6 space-y-4'>
												{tier.includedFeatures.map((feature) => (
													<li key={feature} className='flex space-x-3'>
														<CheckIcon
															className='h-5 w-5 flex-shrink-0 text-green-500'
															aria-hidden='true'
														/>
														<span className='text-sm text-gray-500'>
															{feature}
														</span>
													</li>
												))}
											</ul>
										</div>
									</div>
								</>
							))}
						</div>

						<div
							className='rounded-lg'
							style={
								displayPlan !== 'Basic Plan'
									? {
										float: 'left',
										boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.6)',
										marginRight: '20px',
										marginBottom: '20px',
									}
									: { float: 'left', marginRight: '20px', marginBottom: '20px' }
							}>
							{tiers.map((tier) => (
								<>
									<div
										key={tier.name}
										className={`${location.pathname === '/home' && displayPlan !== 'Pro Plan' ? 'hidden' :'divide-y divide-gray-200 self-center rounded-lg border border-gray-300 shadow-sm w-full'}`}
										style={
											displayPlan === 'Pro Plan'
												? { position: 'relative', backgroundColor: '#EAEAEA' }
												: null
										}>
										{displayPlan === 'Pro Plan' && (
											<div
												style={{
													position: 'absolute',
													top: '-18px',
													right: '-18px',
												}}>
												<CheckCircleIcon
													style={{ fontSize: 'xxx-large' }}
													className='w-5 text-green-500'
													aria-hidden='true'
												/>
											</div>
										)}
										<div className='p-6'>
											<div className='sm:align-center sm:flex sm:flex-col pb-4'>
												<div className='relative flex self-center rounded-lg p-0.5'>
													<button
														onClick={handleAnnual}
														type='button'
														style={
															isAnnual
																? {
																	backgroundColor: 'black',
																	color: 'white',
																	borderRadius: '22px',
																}
																: {
																	backgroundColor: 'grey',
																	color: 'white',
																	borderRadius: '22px',
																}
														}
														className={`${isYearlyActive} relative ml-0.5 w-1/2 whitespace-nowrap rounded-md border py-2.5 text-sm font-medium focus:z-10 focus:outline-none sm:w-auto sm:px-8`}>
														Annual
													</button>
													&emsp;
													<button
														onClick={handleMonthly}
														type='button'
														style={
															isMonthly
																? {
																	backgroundColor: 'black',
																	color: 'white',
																	borderRadius: '22px',
																}
																: {
																	backgroundColor: 'grey',
																	color: 'white',
																	borderRadius: '22px',
																}
														}
														className={`${isMonthlyActive} relative w-1/2 whitespace-nowrap rounded-md  py-2.5 text-sm font-medium shadow-sm focus:z-10 focus:outline-none sm:w-auto sm:px-8`}>
														Monthly
													</button>
												</div>
											</div>
											<h2 className='text-lg font-medium leading-6 text-gray-900'>
												{tier.name}
											</h2>
											<p className='mt-4 text-sm text-gray-500'>
												{tier.description}
											</p>
											<p className='mt-8'>
												<span className='text-3xl font-bold tracking-tight text-gray-900'>
													${tier.priceMonthly || tier.priceYearly}
												</span>{' '}
												<span className='text-base font-medium text-gray-500'>
													{isMonthly ? '/mo' : '/yr'}
												</span>
											</p>
										</div>
										<div className='px-6 pt-6 pb-8'>
											<h3 className='text-sm font-medium text-gray-900'>
												What's included
											</h3>
											<ul className='mt-6 space-y-4'>
												{tier.includedFeatures.map((feature) => (
													<li key={feature} className='flex space-x-3'>
														<CheckIcon
															className='h-5 w-5 flex-shrink-0 text-green-500'
															aria-hidden='true'
														/>
														<span className='text-sm text-gray-500'>
															{feature}
														</span>
													</li>
												))}
											</ul>
										</div>
									</div>
								</>
							))}
						</div>

						<div
							className='rounded-lg'
							style={
								displayPlan === 'Enterprise Plan'
									? {
										float: 'left',
										boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.6)',
										marginRight: '20px',
										marginBottom: '20px',
									}
									: { float: 'left', marginRight: '20px', marginBottom: '20px' }
							}>
							{enterpriseTiers.map((tier) => (
								<>
									<div
										key={tier.name}
										className={`${location.pathname === '/home' && displayPlan !== 'Enterprise Plan' ? 'hidden' : 'pb-8 pr-6 pl-6 self-center rounded-lg border border-gray-300 shadow-sm w-full'}`}
										style={
											displayPlan === 'Enterprise Plan'
												? { position: 'relative', backgroundColor: '#EAEAEA' }
												: null
										}>
										{displayPlan === 'Enterprise Plan' && (
											<div
												style={{
													position: 'absolute',
													top: '-18px',
													right: '-18px',
												}}>
												<CheckCircleIcon
													style={{ fontSize: 'xxx-large' }}
													className='w-5 text-green-500'
													aria-hidden='true'
												/>
											</div>
										)}
										<div className='p-6'>
											<div className='sm:align-center sm:flex sm:flex-col pb-4'>
												<div
													className='relative flex self-center rounded-lg bg-green-100 p-0.5'
													style={{ visibility: 'hidden' }}>
													<h6
														type='heading'
														className={`relative ml-0.5 w-full whitespace-nowrap rounded-md py-2.5 text-sm font-medium focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:w-auto sm:px-8`}
														style={{
															textAlign: 'center',
															visibility: 'hidden',
														}}>
														Enterprise
													</h6>
												</div>
											</div>
											<h2 className='text-lg font-medium leading-6 text-gray-900'>
												{tier.name}
											</h2>
											<p
												className='mt-4 text-sm text-gray-500 text-center'
												style={{ paddingTop: '6rem' }}>
												A customized plan for
												organizations  <br />
												that need
												additional features,
												<br /> control, and support.
											</p>
											<p className='mt-8' style={{ visibility: 'hidden' }}>
												<span className='text-3xl font-bold tracking-tight text-gray-900'>
													${tier.price}
												</span>{' '}
												<span className='text-base font-medium text-gray-500'>
													/mo
												</span>
											</p>
										</div>
										<div
											className='px-9 pt-2 pb-3'
											style={{
												alignItems: 'center',
												display: 'flex',
												justifyContent: 'center',
												visibility: 'hidden',
											}}>
											<ul className='mt-6 space-y-4'>
												{tier.includedFeatures.map((feature) => (
													<li key={feature} className='flex space-x-3'>
														<CheckIcon
															className='h-5 w-5 flex-shrink-0 text-green-500'
															aria-hidden='true'
														/>
														<span className='text-sm text-gray-500'>
															{feature}
														</span>
													</li>
												))}
											</ul>
										</div>
										<div
											className='relative flex self-center mb-4 p-0.5 py-4'
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}>
											<button
												onClick={(e) => {
													window.location.href = 'mailto:koda@imanyco.com';
													e.preventDefault();
												}}
												type='button'
												style={{
													backgroundColor: 'black',
													color: 'white',
													borderRadius: '22px',
												}}
												className={` relative ml-0.5 w-1/2 whitespace-nowrap rounded-md border py-2.5 text-sm font-medium focus:z-10 focus:outline-none sm:w-auto sm:px-8`}>
												Contact Sales
											</button>
										</div>
									</div>
								</>
							))}
						</div>
					</div>
				</div>
			</div>

			<Transition.Root show={displayBuyModal} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={setDisplayBuyModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 overflow-y-hidden'>
						<div className='flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
								<center>
									<Dialog.Panel className='relative transform overflow-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
										<div className='flex sm:flex sm:items-start'>
											<div className='absolute top-0 right-0 pt-4 pr-4 sm:block'>
												<button
													type='button'
													className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
													onClick={() => {
														setDisplayBuyModal(false);
													}}>
													<span className='sr-only'>Close</span>
													<XMarkIcon className='h-6 w-6' aria-hidden='true' />
												</button>
											</div>
											<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full'>
												<Dialog.Title
													as='h3'
													className={`text-2xl mt-7 font-bold leading-6 text-gray-900`}>
													Please Enter Your Payment Details
												</Dialog.Title>
												<div className='mt-2 flex-col lg:flex-row p-4 space-y-2 text-left w-full'>

													<div className='flex flex-col w-full items-start justify-start space-y-4'>
														<CheckoutForm isMonthly={isMonthly} />
													</div>

												</div>
											</div>
										</div>
									</Dialog.Panel>
								</center>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			{/* Manage Subscription Modal */}
			<Transition.Root show={isSubscriptionModal} as={Fragment}>
				<Dialog
					as='div'
					className='relative z-10'
					onClose={setIsSubscriptionModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 overflow-y-auto'>
						<div
							className='flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0'
							style={{ alignItems: 'center' }}>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
								<center>
									<Dialog.Panel
										className='relative transform overflow-hidden rounded-2xl bg-white px-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'
										style={{ marginBottom: '5rem' }}>
										<div className='flex sm:flex sm:items-start'>
											<div className='absolute top-0 right-0 pt-4 pr-4 sm:block'>
												<button
													type='button'
													className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
													onClick={() => setIsSubscriptionModal(false)}>
													<span className='sr-only'>Close</span>
													<XMarkIcon className='h-6 w-6' aria-hidden='true' />
												</button>
											</div>
											<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full'>
												<Dialog.Title
													as='h3'
													className='text-xl font-bold leading-6 text-gray-900 justify-center border-b-2 border-gray-300 m-2 pb-4'>
													{displayPlan === 'Pro Plan'
														? 'Change Billing Cycle'
														: 'Upgrade to Pro Plan'}
												</Dialog.Title>
												<div className='mt-2 flex-col lg:flex-row p-4 space-y-2 text-left w-full'>
													<div className='flex flex-col w-full'>
														{/* <!-- Component Start --> */}

														{displayPlan === 'Pro Plan' ? (
															<>
																<div className='subscriptiongrid'>
																	{currentUser?.subscription === "monthly" ? (
																		<label className='card'>
																			<input
																				name='plan'
																				className='radio'
																				type='radio'
																				value='annual'
																				checked={selectedPlan === "annual"}
																				onChange={handlePlanChange}
																			/>
																			<span className='plan-details'>
																				<span className='plan-type'>Annual</span>
																				<span className='plan-cost'>
																					$155<span className='slash'>/</span>
																					<abbr className='plan-cycle' title='month'>
																						year
																					</abbr>
																				</span>
																				<span>Pay for a full year.</span>
																			</span>
																		</label>
																	) : (
																		<label className='card'>
																			<input
																				name='plan'
																				className='radio'
																				type='radio'
																				value='monthly'
																				checked={selectedPlan === "monthly"}
																				onChange={handlePlanChange}
																			/>
																			<span className='hidden-visually'>
																				Pro - $16.99 per month, up to 2 people per session, 30 minutes per session, 15 minutes of diarization per month
																			</span>
																			<span className='plan-details' aria-hidden='true'>
																				<span className='plan-type'>Monthly</span>
																				<span className='plan-cost'>
																					$16.99<span className='slash'>/</span>
																					<span className='plan-cycle'>mo</span>
																				</span>
																				<span>Pay monthly, cancel at any time.</span>
																			</span>
																		</label>
																	)}

																</div>
																<center>
																	<div>
																		{success && (
																			<Alert className="text-green-500" variant="filled" severity="success">
																				{success}
																			</Alert>
																		)}

																		{error && (
																			<Alert className="text-red-500" variant="filled" severity="error">
																				{error}
																			</Alert>
																		)}
																	</div>
																</center>

																<div className='flex flex-col items-center my-5'>
																	<button
																		type='button'
																		className='inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-yellow-500 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400'
																		style={{
																			padding: '1em 2em',
																			borderRadius: '30px',
																			color: 'black',
																			backgroundColor: '#D8F55A',
																			textAlign: 'center',
																			fontSize: '12px',
																			fontWeight: 'bold',
																			fontFamily: 'sans-serif',
																		}}
																		onClick={changePlan}
																	>
																		Confirm Change
																	</button>
																	<a
																		className='text-black underline mt-14'
																		onClick={handleCancelConfirm}
																		style={{ cursor: 'pointer' }}
																	>
																		Cancel Subscription
																	</a>

																	<Transition.Root show={cancelConfirm} as={Fragment}>
																		<Dialog as='div' className='relative z-10' onClose={() => setCancelConfirm(false)}>
																			<Transition.Child
																				as={Fragment}
																				enter='ease-out duration-300'
																				enterFrom='opacity-0'
																				enterTo='opacity-100'
																				leave='ease-in duration-200'
																				leaveFrom='opacity-100'
																				leaveTo='opacity-0'>
																				<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
																			</Transition.Child>

																			<div className='fixed inset-0 z-10 overflow-y-hidden'>
																				<div className='flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0'>
																					<Transition.Child
																						as={Fragment}
																						enter='ease-out duration-300'
																						enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
																						enterTo='opacity-100 translate-y-0 sm:scale-100'
																						leave='ease-in duration-200'
																						leaveFrom='opacity-100 translate-y-0 sm:scale-100'
																						leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
																						<center>
																							<Dialog.Panel className='relative transform overflow-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
																								<div className='flex sm:flex sm:items-start'>
																									<div className='absolute top-0 right-0 pt-4 pr-4 sm:block'>
																										<button
																											type='button'
																											className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
																											onClick={() => {
																												setCancelConfirm(false);
																											}}>
																											<span className='sr-only'>Close</span>
																											<XMarkIcon className='h-6 w-6' aria-hidden='true' />
																										</button>
																									</div>
																									<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full'>
																										<Dialog.Title
																											as='h5'
																											className={`text-lg mt-7 font-bold leading-6 text-gray-900`}>
																											Are you sure you want to cancel your subscription?
																										</Dialog.Title>

																										<div className='mt-2 flex-col lg:flex-row p-4 space-y-2 text-left w-full'>

																											<div className='flex flex-col w-full items-center justify-center space-y-4'>

																												<Button
																													size='small'
																													type='button'
																													className='inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
																													style={{
																														padding: '1em 2em',
																														border: 0,
																														borderRadius: '30px',
																														color: 'black',
																														backgroundColor: '#D8F55A',
																														textAlign: 'center',
																														fontSize: '12px',
																														fontWeight: 'bold',
																														fontFamily: 'sans-serif',
																													}}
																													onClick={cancelPlan}>
																													Confirm Cancellation
																												</Button>

																											</div>

																										</div>
																									</div>
																								</div>
																							</Dialog.Panel>
																						</center>
																					</Transition.Child>
																				</div>
																			</div>
																		</Dialog>
																	</Transition.Root>
																</div>
															</>
														) : (
															<>
																<div className='subscriptiongrid'>
																	<label className='card'>
																		<input
																			name='plan'
																			className='radio'
																			type='radio'
																			onChange={handleAnnual}
																		/>
																		<span className='plan-details'>
																			<span className='plan-type'>Annual</span>
																			<span className='plan-cost'>
																				$155<span className='slash'>/</span>
																				<abbr className='plan-cycle'>
																					year
																				</abbr>
																			</span>
																			<span>Pay for a full year.</span>
																		</span>
																	</label>
																	<label className='card'>
																		<input
																			name='plan'
																			className='radio'
																			type='radio'
																			onChange={handleMonthly}
																		/>
																		<span className='hidden-visually'>
																			Pro - $16.99 per month, up to 2 people per session, 30 minutes per session, 15 minutes of diarization per month
																		</span>
																		<span
																			className='plan-details'
																			aria-hidden='true'>
																			<span className='plan-type'>Monthly</span>
																			<span className='plan-cost'>
																				$16.99<span className='slash'>/</span>
																				<span className='plan-cycle'>mo</span>
																			</span>
																			<span>
																				Pay monthly, cancel at any time.
																			</span>
																		</span>
																	</label>
																</div>
																<div className='mt-5 sm:mt-4 sm:flex justify-center sm:flex-row-reverse'>
																	<Button
																		size='small'
																		type='button'
																		className='inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
																		style={{
																			padding: '1em 2em',
																			border: 0,
																			borderRadius: '30px',
																			color: 'black',
																			backgroundColor: '#D8F55A',
																			textAlign: 'center',
																			fontSize: '12px',
																			fontWeight: 'bold',
																			fontFamily: 'sans-serif',
																		}}
																		onClick={() => setDisplayBuyModal(true)}>
																		Confirm Subscription
																	</Button>
																</div>
															</>
														)}

														{/* <!-- Component End  --> */}
													</div>
												</div>
											</div>
										</div>
									</Dialog.Panel>
								</center>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
}

export default SubscriptionSettings;