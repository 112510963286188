import React from 'react';
import HistoryContainer from '../components/Containers/HistoryContainer';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

function History({
	display,
	handleSubscription,
}) {
	const currentUser = useSelector((state) => state.user.currentUser);

	//google analytics
	ReactGA.initialize('UA-189074005-1');
	ReactGA.pageview('/history');
	return (
		<>
			<Helmet>
        <title>Imanyco Koda - Session History</title>
      </Helmet>
			<div
				className={`${display} w-full h-screen md:pl-24 flex flex-col p-1 space-y-2`}
				style={
					window.innerWidth >= 768 && currentUser
						? { paddingBottom: 0 }
						: { paddingBottom: 57 }
				}>
				<HistoryContainer
					handleSubscription={handleSubscription}
				/>
			</div>
		</>
	);
}

export default History;
