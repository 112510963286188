import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSpeakerNames, setIsEditing } from '../redux/reducers/conversationReducer';
const axios = require('axios');
const { v4: uuid } = require('uuid');

//create session room
export const createRoom = async (e, currentUser) => {
  e.preventDefault();
  const roomId = uuid();
  let userConversation = {
    UserId: currentUser?.id || currentUser,
    roomId: roomId,
    participants: [currentUser?.id || currentUser],
  };
  
  try {
    const response = await axios.post(`/api/v1/conversations/create-room`, userConversation);
    return response.data.roomId;
  } catch (error) {
    console.error(`Error creating room: ${error}`);
    // Handle the error case
    return null;
  }
};

//add participant to participants array in conversation
export const addParticipant = (currentUser, roomName) => {
  axios.patch(`/api/v1/conversations/${roomName}/add-participant/${currentUser?.id}`)
    .catch((error) => {
      console.log(`Error adding participant to conversation: ${error}`);
      // Handle the error case
    });
};

// delete current session
export const handleDeleteSession = (currentUser, roomUUID) => {
  axios.patch(`/api/v1/conversations/${roomUUID}/delete-conversation/${currentUser?.id}`)
    .then((response) => {
      // Handle the successful response if needed
    })
    .catch((error) => {
      console.error(error);
      // Handle the error case
    });
};

// Session Transcription and Diarization container
export function SessionResultContainer(bgColorEnabled, messages, isAdmin, singleSpeaker, isFullScreen, interimTranscript, uuid, currentUser) {
  const currentFontSizeIndex = useSelector((state) => state.accessibility.currentFontSizeIndex);
  const fontSize = useSelector((state) => state.accessibility.fontSizes);
  const currentFontSize = fontSize[currentFontSizeIndex];
  const currentColorPalette = useSelector((state) => state.accessibility.currentColorPalette);
  const colorPalettes = useSelector((state) => state.accessibility.colorPalettes);
  const currentCaptionColor = colorPalettes[currentColorPalette];
  let messageText = '';
  const dispatch = useDispatch();
  const speakerNames = useSelector((state) => state.conversation.speakerNames);
  const [editingSpeaker, setEditingSpeaker] = useState(null);
  const [editName, setEditName] = useState('');
  const [hoveredSpeaker, setHoveredSpeaker] = useState(null);

  const updateSpeakerName = (speakerNumber, newName) => {
    const user = currentUser?.id;
    const updatedSpeakerNames = { ...speakerNames[user], [speakerNumber]: newName };
    dispatch(setSpeakerNames({
      ...speakerNames,
      [user]: updatedSpeakerNames
    }));

    setEditingSpeaker(null);
    setEditName('');
  };

  const handleEditClick = (speakerNumber) => {
    setEditingSpeaker(speakerNumber);
    setEditName(speakerNames?.[speakerNumber] || '');
    dispatch(setIsEditing(true));
  };

  const getMessageTextColor = () => {
    if (!bgColorEnabled) return '';
    return currentCaptionColor[6];
  };

  const getMessageBgColor = (speaker) => {
    if (!bgColorEnabled || speaker < 1 || speaker > 6) return '';
    return currentCaptionColor[speaker - 1];
  };

  if (messages.length === 0) {
    return (
      <div className="p-10 mt-4" style={{ fontSize: currentFontSize, lineHeight: '1.5em' }}>
        {isAdmin ? (
          <>
            <p>Press the play button to start your transcription!</p>
          </>
        ) : (
          <>
            <p>Waiting for session to start... messages will appear here!</p>
          </>
        )}
      </div>
    );
  } else {
    if (singleSpeaker || (messages.length > 1 && messages[1].singleSpeaker)) {
      messages.forEach((message) => {
        if (message.text.trim() !== '') {
          messageText += `${message.text} `;
        }
      });

      messageText += interimTranscript;

      return (
        <div className="" style={{width: '-1.5em'}}>
          <p
            className={`block p-2 px-4 ${isFullScreen ? 'mt-12' : 'mt-4'} ml-4 rounded-full ${singleSpeaker || (messages.length > 1 && messages[1].singleSpeaker) ? '' : 'border shadow-lg'
              }`}
            style={{ fontSize: currentFontSize, lineHeight: '1.5em' }}
          >
            {messageText}
          </p>
        </div>
      );
    } else {
      return (
        <div className="">
          <ul className={`space-y-1 ${isFullScreen ? 'mt-12' : 'mt-4'} ml-4 pb-10`}>
            {messages.map((message, index) => {
              if (!message || !message.text || message.text.trim() === '') {
                return null;
              }

              let userSpeakerNames = null;

              if (speakerNames?.[currentUser?.id]) {
                userSpeakerNames = speakerNames[currentUser.id];
              }

              const speakerName =
                (userSpeakerNames && userSpeakerNames[message.speaker]) ||
                `Speaker ${message.speaker}`;

              const speakerId = index;
              const isEditing = editingSpeaker === speakerId;
              const isHovered = hoveredSpeaker === speakerId;

              return (
                <li key={uuid()} className="flex justify-start p-1">
                  <div>
                    <p>
                      {isEditing && isAdmin ? (
                        <>
                          <input
                            value={editName}
                            placeholder='enter name here...'
                            onChange={(e) => setEditName(e.target.value)}
                            autoFocus
                          />
                          <button
                            onClick={() => {
                              updateSpeakerName(message.speaker, editName);
                              setEditingSpeaker(null);
                              dispatch(setIsEditing(false));
                            }}
                            style={{ backgroundColor: 'green', color: 'white', borderRadius: '50%', width: '30px', height: '30px', textAlign: 'center', lineHeight: '1.5em', marginLeft: '10px' }}
                          >
                            ✓
                          </button>
                          <button
                            onClick={() => {
                              setEditName(speakerNames?.[currentUser?.id]?.[message.speaker] || `Speaker ${message.speaker}`);
                              setEditingSpeaker(null);
                              dispatch(setIsEditing(false));
                            }}
                            style={{ backgroundColor: 'red', color: 'white', borderRadius: '50%', width: '30px', height: '30px', textAlign: 'center', lineHeight: '1.5em', marginLeft: '10px' }}
                          >
                            ✕
                          </button>
                        </>
                      ) : (
                        <span
                          className={`font-bold px-4 ${isHovered && isAdmin ? 'cursor-pointer' : ''}`}
                          style={{ fontSize: currentFontSize }}
                          onMouseEnter={() => isAdmin && setHoveredSpeaker(speakerId)}
                          onMouseLeave={() => isAdmin && setHoveredSpeaker(null)}
                          onClick={() => isAdmin && handleEditClick(speakerId)}
                        >
                          {isAdmin && isHovered && (
                            <span style={{ color: 'black' }}>&#x270E;</span>
                          )}
                          {speakerName}:
                        </span>
                      )}
                    </p>

                    <p
                      className="block rounded-3xl border shadow-lg"
                      style={{
                        fontSize: currentFontSize,
                        padding: currentFontSize === '16px' ? '16px' : currentFontSize,
                        marginTop: currentFontSize === '16px' ? '8px' : currentFontSize,
                        marginBottom: currentFontSize === '16px' ? null : currentFontSize,
                        lineHeight: '1.5em',
                        textAlign: 'justify',
                        backgroundColor: getMessageBgColor(message.speaker),
                        color: getMessageTextColor(),
                      }}
                    >
                      {message.text}
                    </p>
                  </div>
                </li>
              );
            })}
            {messages && messages.length > 0 && messages[messages.length - 1].interimText !== '' && (
              <li key={uuid()} className="flex justify-start p-2">
                <div>
                  <p>
                    <span className="font-bold px-2 pb-10" style={{ fontSize: currentFontSize }}>
                      Detecting Speakers...
                    </span>
                  </p>

                  <p
                    className="block p-2 px-4 pb-10 rounded-3xl text-"
                    style={{
                      fontSize: currentFontSize,
                      lineHeight: '1.5em',
                    }}
                  >
                    {messages[messages.length - 1].interimText}
                  </p>
                </div>
              </li>
            )}
          </ul>
        </div>
      );
    }
  }
}
