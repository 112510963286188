import React, { useState } from "react";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Alert } from "@mui/material";
import { loggedIn } from "../../redux/reducers/userReducer";
require('dotenv').config();


function GoogleLoginButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const onSuccess = (response) => {
    fetch("/api/v1/users/login", {
      method: "POST",
      body: JSON.stringify({
        email: response.profileObj.email,
        password: response.profileObj.googleId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (res.ok) {
          dispatch(loggedIn(data));
          navigate("/home");
        } else {
          throw data;
        }
      })
      .catch((res) => {
        if (res) {
          setError("Email doesn't exist")
        }
    });
  };

  return (
    <div>
    <div>
    {error && (
      <Alert
        className="text-red-500"
        variant="filled"
        severity="error"
        icon={false}
      >
        {error}
      </Alert>
    )}
</div>
<div>
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_ID}
      buttonText="Login with Google"
      onSuccess={onSuccess}
    />
    </div>
  </div>
  );
}

export default GoogleLoginButton;
