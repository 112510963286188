import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from 'react-helmet';
import { FormControl, Alert } from "@mui/material";

import { loggedIn } from "../redux/reducers/userReducer";
import { handleRegister } from '../utilities/user.js';
import GoogleRegisterButton from "../components/Account/GoogleRegisterButton";

import koda_logo_black from "../assets/koda_logo_black.png";
import { checkPassword } from "../utilities/user";
import Mixpanel from 'mixpanel-browser';

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); 
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [showPasswordHelper, setShowPasswordHelper] = useState(false)
  const [avatar, setAvatar] = useState("")
  const registerUser = handleRegister(
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    phoneNumber,
    avatar,
    setError,
    setEmail,
    setPassword,
    dispatch,
    navigate,
    loggedIn,
    )
  const handleClick = () => {
    Mixpanel.track('User Signup Button Clicked')
  }

  
  return (
    <>
    	<Helmet>
        <title>Imanyco Koda - Sign Up</title>
      </Helmet>
      <div className="flex justify-center m-4">
        <div className="flex flex-col items-center justify-center p-6 rounded-lg shadow-lg  border space-y-4 w-full sm:w-3/4">
          <img src={koda_logo_black} width={250} layout="fixed" alt="Logo" />

          <h1 className="text-lg p-2">Join the Conversation</h1>
          <div className="flex flex-row justify-center hover:bg-gray-200 m-2">
            {/* <GoogleRegisterButton /> */}
          </div>
          {/* <div className="flex flex-col items-center text-xs">
            <h1>OR</h1>
          </div> */}
          <div className="w-full items-center justify-center space-x-4 space-y-4">
            <form
              className="flex flex-col w-full items-center justify-center space-y-4"
              onSubmit={registerUser}
            >
              <FormControl required>
                <div className="flex flex-col w-full space-y-4 justify-center items-center">
                  <div
                    className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}
                  >
                    <input
                      type="text"
                      placeholder="First Name"
                      className={`w-full form-control px-2 py-4 rounded-lg `}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div
                    className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}
                  >
                    <input
                      type="text"
                      className={`form-control w-full form-control px-2 py-4 rounded-lg `}
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                  <div
                    className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}
                  >
                    <input
                      type="email"
                      className={`form-control w-full form-control px-2 py-4 rounded-lg `}
                      placeholder="Email (ex. jdoe@yahoo.com)"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      required
                    />
                  </div>
                  <div className={`relative w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm`}>
                    <input
                      type="password"
                      className={`form-control w-full form-control px-2 py-4 rounded-lg`}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        checkPassword(e.target.value, setIsPasswordValid);
                      }}
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{6,}"
                      title="Password must contain at least 6 characters including 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character."
                      onFocus={() => setShowPasswordHelper(true)}
                      onBlur={() => setShowPasswordHelper(false)}
                      required
                    />
                    {showPasswordHelper && (
                      <div className="absolute mt-14 bg-white rounded-lg border border-gray-300 text-gray-700 px-3 py-2 text-xs">
                        <ul className="list-disc pl-4">
                          <li className={`text-${password.length >= 6 ? 'green' : 'red'}-500`}>
                            At least 6 characters long
                          </li>
                          <li className={`text-${/(?=.*[a-z])/.test(password) ? 'green' : 'red'}-500`}>
                            Contains a lowercase letter
                          </li>
                          <li className={`text-${/(?=.*[A-Z])/.test(password) ? 'green' : 'red'}-500`}>
                            Contains an uppercase letter
                          </li>
                          <li className={`text-${/(?=.*\d)/.test(password) ? 'green' : 'red'}-500`}>
                            Contains a number
                          </li>
                          <li className={`text-${/(?=.*\W)/.test(password) ? 'green' : 'red'}-500`}>
                            Contains a special character
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  <div
                    className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}
                  >
                    <input
                      type="password"
                      className={`form-control w-full form-control px-2 py-4 rounded-lg `}
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div
                    className={`w-full md:w-96 flex flex-col border rounded-lg font-bold text-sm leading-tight shadow-sm `}
                  >
                    <input
                      type="text"
                      className={`form-control w-full form-control px-2 py-4 rounded-lg `}
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </FormControl>
              {error && (
                <Alert
                  className="text-red-500"
                  variant="filled"
                  severity="error"
                >
                  {error}
                </Alert>
              )}
              <div
                className={`w-full md:w-96 flex flex-row border rounded-lg font-bold text-sm leading-tight shadow-sm `}
              >
                <button
                  onClick={handleClick}
                  className={`w-full md:w-96 px-2 py-4 rounded-lg flex flex-row justify-center space-x-4 text-black ${isPasswordValid ? 'bg-button-primary hover:bg-button-primary-hover active:bg-emerald-800' : 'bg-gray-400 cursor-not-allowed'} font-bold text-sm leading-tight shadow-md ${isPasswordValid ? 'hover:bg-emerald-700' : ''} ${isPasswordValid ? 'hover:shadow-lg focus:bg-emerald-700 focus:shadow-lg' : ''} ${isPasswordValid ? 'focus:outline-none focus:ring-0' : ''} transition duration-150 ease-in-out`}

                  disabled={!isPasswordValid}
                >
                  <span>Create Account</span>
                </button>
              </div>
            </form>
          </div>
          <div className="flex flex-col justify-center text-center">
            <h1>Already have an account?</h1>
            <Link to="/login" className="text-blue-600">
              Login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default Register;
